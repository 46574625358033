// axios instance
import axios, { AxiosInstance } from "axios";
import { BehaviorSubject, Observable, distinctUntilChanged } from "rxjs";

import { getApiRoot } from "./util-helper";
import { getTokenObservable } from "./util-token";

const requestCountSubject = new BehaviorSubject<number>(0);

const requestCountObservable = requestCountSubject.pipe(distinctUntilChanged());

const requestErrorCountSubject = new BehaviorSubject<number>(0);
const requestErrorCountObservable = requestErrorCountSubject.pipe(
  distinctUntilChanged()
);

function createAxiosInstance(token: string) {
  let headers = {};
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }
  const instance = axios.create({
    baseURL: getApiRoot(),
    method: "POST",
    maxBodyLength: 10_000_000,
    maxContentLength: 10_000_000,
    headers: headers,
  });
  instance.interceptors.request.use((config) => {
    if (!config.url) {
      config.url = "/graphql";
    }
    requestCountSubject.next(requestCountSubject.value + 1);
    // console.log("requesting", config.data);
    return config;
  });
  instance.interceptors.response.use(
    function (response) {
      requestCountSubject.next(requestCountSubject.value - 1);
      return response;
    },
    function (error) {
      requestCountSubject.next(requestCountSubject.value - 1);
      requestErrorCountSubject.next(requestErrorCountSubject.value + 1);
      return Promise.reject(error);
    }
  );
  return instance;
}

const axiosInstance = createAxiosInstance("");

const axiosSubject = new BehaviorSubject<AxiosInstance>(axiosInstance);

getTokenObservable().subscribe({
  next: (token) => {
    if (token) {
      const axiosInstance = createAxiosInstance(token);
      axiosSubject.next(axiosInstance);
    }
  },
});

export function getAxiosInstance(): AxiosInstance {
  return axiosSubject.getValue();
}

export function getAxiosInstanceObservable(): Observable<AxiosInstance> {
  return axiosSubject.asObservable();
}

export function getRequestCountObservable(): Observable<number> {
  return requestCountObservable;
}

export function getRequestErrorCountObservable(): Observable<number> {
  return requestErrorCountObservable;
}
