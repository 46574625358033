// user

import { BehaviorSubject, Observable, share, map } from "rxjs";
import { jsonToGraphQLQuery } from "json-to-graphql-query";

import { getAxiosInstance } from "./util-axios";
import { getRoleCode, getSubRoleCode } from "./util-role";

type UserRole = {
  id: string;
  roleCode: string;
};

type LineUser = {
  pictureUrl: string;
};

type User = {
  id: string;
  code: string;
  name: string;
  lineUser: LineUser;
  userRoles: UserRole[];
};

const userSubject = new BehaviorSubject<User | null>(null);
userSubject.pipe(share());

export async function queryMe() {
  const axiosInstance = getAxiosInstance();
  const query = jsonToGraphQLQuery({
    query: {
      me: {
        id: true,
        code: true,
        name: true,
        lineUser: {
          pictureUrl: true,
        },
        userRoles: {
          id: true,
          roleCode: true,
        },
      },
    },
  });

  const response = await axiosInstance.request({
    data: {
      query,
    },
  });

  if (response.data.data.me) {
    return response.data.data.me;
  }
  return null;
}

export function getUser() {
  return userSubject.getValue();
}

export function setUser(user: User) {
  userSubject.next(user);
}

export function getUserObservable(): Observable<User | null> {
  return userSubject.asObservable();
}

export function isAccessible$(roles: string[]) {
  return getUserObservable().pipe(
    map((user) => {
      if (!user) {
        return false;
      }
      const userRoles = user.userRoles.map((userRole) => userRole.roleCode);
      return (
        roles.some((role) => userRoles.includes(role)) ||
        userRoles.includes(`${getRoleCode().MAIN}_${getSubRoleCode().MASTER}`)
      );
    })
  );
}
