import {
  CoatingType,
  SpottingType,
  SpottingBlockType,
  TransportTagStatusType,
} from "./generated-graphql-types";

export type Translation = {
  Thai: string;
  English: string;
};

export const CoatingTypeTranslation: Record<CoatingType, Translation> = {
  GLOSSY_PVC: {
    Thai: "PVC เงา",
    English: "Glossy PVC",
  },
  MATT_PVC: {
    Thai: "PVC ด้าน",
    English: "Matt PVC",
  },
  METALIZE: {
    Thai: "Metalize",
    English: "Metalize",
  },
  UV: {
    Thai: "UV",
    English: "UV",
  },
  HG: {
    Thai: "โฮโลแกรม (HG)",
    English: "Hologram (HG)",
  },
  OTHER: {
    Thai: "อื่นๆ",
    English: "Others",
  },
};

export const SpottingTypeTranslation: Record<SpottingType, Translation> = {
  NORMAL: {
    Thai: "ปกติ",
    English: "Normal",
  },
  EMBOSE: {
    Thai: "นูน",
    English: "Embose",
  },
  X_EMBOSE: {
    Thai: "นูนพิเศษ",
    English: "Extra Embose",
  },
  TEXTURE: {
    Thai: "ผ้า",
    English: "Texture",
  },
};

export const SpottingBlockTranslation: Record<SpottingBlockType, Translation> =
  {
    NEW_FILM: {
      Thai: "ฟิล์มใหม่",
      English: "New Film",
    },
    EXISTING_FILM: {
      Thai: "ฟิล์มเก่า",
      English: "Existing Film",
    },
  };

export const TransportTagStatusTypeTranslation: Record<
  TransportTagStatusType,
  Translation
> = {
  WAITING: {
    Thai: "รอส่ง",
    English: "Waiting",
  },
  IN_TRANSIT: {
    Thai: "กำลังส่ง",
    English: "In transit",
  },
  DELIVERED: {
    Thai: "ส่งแล้ว",
    English: "Delivered",
  },
};
