import { JobProgressSummary, Job } from "./generated-graphql-types";

function getLesserProgressSummary(
  p1: JobProgressSummary,
  p2: JobProgressSummary
): JobProgressSummary {
  const p1Amount = (p1.progressAmount || 0) - (p1.wasteAmount || 0);
  const p2Amount = (p2.progressAmount || 0) - (p2.wasteAmount || 0);
  return p2Amount < p1Amount ? p2 : p1;
}

export function getApproximateProgressValue(
  pages: number,
  page1Progress: JobProgressSummary,
  page2Progress: JobProgressSummary
): number {
  if (pages === 1) {
    return (
      (page1Progress.progressAmount || 0) - (page1Progress.wasteAmount || 0)
    );
  }
  const lesserProgress = getLesserProgressSummary(page1Progress, page2Progress);

  return (
    (lesserProgress.progressAmount || 0) - (lesserProgress.wasteAmount || 0)
  );
}

export function isFinalizableOrder(jobs: Job[]) {
  const allProgress = jobs.map((job) =>
    getApproximateProgressValue(
      job.jobPages,
      job.page1Progress,
      job.page2Progress
    )
  );
  return allProgress.every((v) => v > 0);
}
