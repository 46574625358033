import { liffIsInClient, liffOpenWindow } from "./util-line-liff";
import printJS from "print-js";

// print PDF
export function printPDF(url: string) {
  if (liffIsInClient()) {
    return liffOpenWindow(url, true);
  }
  printJS({
    printable: url,
    type: "pdf",
    showModal: true,
    modalMessage: "กำลังโหลดไฟล์",
  });
}
