import numeral from "numeral";

export function formatInt(n: number) {
  return numeral(n).format("0,0");
}

export function formatDecimal(n: number, digits = 2) {
  const formatStr = `0,0.${Array(digits).fill(0).join("")}`;
  return numeral(n).format(formatStr);
}
