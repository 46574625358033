import { BehaviorSubject, Observable, share, distinctUntilChanged } from "rxjs";

// For confirmation dialog

var _CONFIRM_TEXT = "CONFIRM";
var _DELETE_TEXT = "DELETE";

export type TConfirmationDialogData = {
  confirmationText: string;
  insensitiveConfirmText?: boolean;
  data?: any;
  onConfirm: (data: any) => void;
  onCancel: () => void;
};

export const DEFAULT_CONFIRM_TEXT = _CONFIRM_TEXT;
export const DEFAULT_DELETE_TEXT = _DELETE_TEXT;

const confirmationDialogSubject = new BehaviorSubject<
  TConfirmationDialogData | undefined
>(undefined);

export function createConfirmationDialog(confirmData: TConfirmationDialogData) {
  confirmationDialogSubject.next(confirmData);
}

export function getConfirmationDialogObservable(): Observable<
  TConfirmationDialogData | undefined
> {
  return confirmationDialogSubject.pipe(share(), distinctUntilChanged());
}

// Backdrop
export type TBackdropData = {
  open: boolean;
  currentValue: number;
  maxValue: number;
};

let currentBackdropData: TBackdropData = {
  open: false,
  currentValue: 0,
  maxValue: 1,
};

const backdropSubject = new BehaviorSubject<TBackdropData>(currentBackdropData);

backdropSubject.subscribe({
  next: (v) => {
    currentBackdropData = v;
  },
});

export function setBackdropVisibility(data: TBackdropData) {
  backdropSubject.next(data);
}

export function getBackdropObservable(): Observable<TBackdropData> {
  return backdropSubject.pipe(share(), distinctUntilChanged());
}

export function incrementBackdropValue() {
  setBackdropVisibility({
    ...currentBackdropData,
    currentValue: currentBackdropData.currentValue + 1,
  });
}

const pageTitleSubject = new BehaviorSubject<string>("Web App");

export function setPageTitle(title: string) {
  pageTitleSubject.next(title);
}

export function getPageTitleObservable(): Observable<string> {
  return pageTitleSubject.pipe(share(), distinctUntilChanged());
}
