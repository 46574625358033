// notify bar
import { BehaviorSubject, Observable } from "rxjs";

type NotifyVariant = "default" | "success" | "error" | "info" | "warning";
type NotifySubjectType = {
  variant: NotifyVariant | undefined;
  message: string | undefined;
};

const notifyBarSubject = new BehaviorSubject<NotifySubjectType | undefined>(
  undefined
);

export function createNotify(
  message: string,
  variant: NotifyVariant = "default"
) {
  notifyBarSubject.next({
    message,
    variant,
  });
}

export function getNotifyObservable(): Observable<
  NotifySubjectType | undefined
> {
  return notifyBarSubject.asObservable();
}
