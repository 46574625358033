// date
import {
  parseISO,
  format,
  formatDistance,
  addMinutes,
  getUnixTime,
  isBefore,
  subDays,
} from "date-fns";
import th from "date-fns/locale/th";

export function parseISODateTime(dateString: string): Date {
  return parseISO(dateString);
}

export function formatDateTime(d: Date, fmt = "dd/MM/yyyy HH:mm"): string {
  return format(d, fmt);
}

export function formatShortDate(d: Date): string {
  return format(d, "EEEE d MMM yyyy", { locale: th });
}

export function formatShortDateWithTime(d: Date): string {
  return format(d, "EEEE d MMM yyyy HH:mm", { locale: th });
}

export function formatTime(d: Date): string {
  return format(d, "HH:mm");
}

export function formatDateDistance(d: Date): string {
  return format(d, "dd/MM HH:mm");
  // if (isBefore(d, subDays(new Date(), 1))) {
  //   return format(d, "dd/MM/yyyy HH:mm");
  // }
  // return formatDistance(d, new Date(), {
  //   locale: th,
  //   includeSeconds: true,
  //   addSuffix: true,
  // });
}

export function expiresTimeStamp(minutes: number) {
  return addMinutes(new Date(), minutes);
}

export function dateToUnixTimestamp(d: Date): number {
  return getUnixTime(d);
}
