// window onload
// import liff from "@line/liff";
// import liff from "@line/liff/core";
// import LiffLogin from "@line/liff/login";
// import IsLoggedIn from "@line/liff/is-logged-in";
// import LiffLogout from "@line/liff/logout";
// import GetProfile from "@line/liff/get-profile";
// import GetAccessToken from "@line/liff/get-access-token";

// liff.use(new LiffLogin());
// liff.use(new LiffLogout());
// liff.use(new GetProfile());
// liff.use(new IsLoggedIn());
// liff.use(new GetProfile());
// liff.use(new GetAccessToken());

import { Subscription } from "rxjs";

import { getAxiosInstance } from "./util-axios";
import { setUser, queryMe, getUser } from "./util-user";
import {
  setToken,
  setRefreshToken,
  getRefreshToken,
  setLiffToken,
  getLiffTokenObservable,
  getTokenObservable,
  getRefreshTokenObservable,
  refreshCurrentToken,
} from "./util-token";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import {
  liffGetAccessToken,
  liffGetProfile,
  liffInit,
  liffIsLoggedIn,
  liffLogin,
  liffLogout,
} from "./util-line-liff";

export async function logout() {
  const logoutMutation = `
  mutation {
    logout {
      accessToken,
      refreshToken,
    }
  }
`;

  await getAxiosInstance().request({
    data: {
      query: logoutMutation,
    },
    headers: {
      Authorization: `Bearer ${getRefreshToken()}`,
    },
  });
  setRefreshToken("");
  setToken("");
  if (liffIsLoggedIn()) {
    liffLogout();
  }
}

let liffTokenSubscription: Subscription = null;
let tokenSubscription: Subscription = null;
let refreshTokenSubscription: Subscription = null;

export function onLoad(ev: Event) {
  if (window.location.href.includes("/auth/")) {
    return;
  }
  // Liff Token
  liffTokenSubscription = getLiffTokenObservable().subscribe({
    next: (newLiffToken) => {
      if (newLiffToken) {
        const gqlSignInWithLineMutation = `mutation {
          signinWithLine(lineToken: "${newLiffToken}") {
            accessToken,
            refreshToken,
          }
        }`;
        getAxiosInstance()
          .request({
            data: {
              query: gqlSignInWithLineMutation,
            },
          })
          .then((res) => {
            if (res.data.error) {
              setRefreshToken("");
              setToken("");
              window.location.href = "/auth/line";
              return;
            }
            const { accessToken, refreshToken } = res.data.data.signinWithLine;
            setRefreshToken(refreshToken);
            setToken(accessToken);
          })
          // update user profile
          .then(() => {
            // return liff.getProfile();
            return liffGetProfile();
          })
          .then((data) => {
            const { pictureUrl, displayName } = data;
            return getAxiosInstance().request({
              data: {
                query: jsonToGraphQLQuery({
                  mutation: {
                    updateLineProfile: {
                      __args: {
                        data: {
                          displayName: {
                            set: displayName,
                          },
                          pictureUrl: {
                            set: pictureUrl,
                          },
                        },
                      },
                      displayName: true,
                      pictureUrl: true,
                    },
                  },
                }),
              },
            });
          });
      }
    },
  });

  // token
  tokenSubscription = getTokenObservable().subscribe({
    next: async (token) => {
      if (token) {
        const userResult = await queryMe();
        setUser(userResult);
      } else {
        setUser(null);
      }
    },
  });

  refreshTokenSubscription = getRefreshTokenObservable().subscribe({
    next: async (refreshToken) => {
      if (refreshToken === "") {
        if (liffIsLoggedIn()) {
          const liffAccessToken = liffGetAccessToken();
          setLiffToken(liffAccessToken);
        } else {
          // liff.login({
          //   redirectUri: window.location.href,
          // });
          liffLogin(window.location.href);
        }
      }
    },
  });

  liffInit().then(() => {
    if (!getUser()) {
      if (!liffIsLoggedIn()) {
        liffLogin();
      } else {
        const liffToken = liffGetAccessToken();
        setLiffToken(liffToken);
      }
    }
    if (getRefreshToken()) {
      refreshCurrentToken();
    }
  });
}

export function onUnLoad(_event) {
  if (liffTokenSubscription) {
    liffTokenSubscription.unsubscribe();
  }
  if (tokenSubscription) {
    tokenSubscription.unsubscribe();
  }
  if (refreshTokenSubscription) {
    refreshTokenSubscription.unsubscribe();
  }
  window.removeEventListener("load", onLoad);
}
