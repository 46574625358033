export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** An arbitrary-precision Decimal type */
  Decimal: any;
};

export enum ActionType {
  Create = "CREATE",
  Delete = "DELETE",
  Update = "UPDATE",
}

export type BoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type CancelOrderInput = {
  cancelReason?: InputMaybe<Scalars["String"]>;
};

export enum CoatingType {
  GlossyPvc = "GLOSSY_PVC",
  Hg = "HG",
  MattPvc = "MATT_PVC",
  Metalize = "METALIZE",
  Other = "OTHER",
  Uv = "UV",
}

export type CreateJobPdfInput = {
  /** Job ID list */
  jobIds: Array<Scalars["String"]>;
  /** Order ID */
  orderId: Scalars["String"];
};

export type CreateTransportTagPdfInput = {
  /** Transport Tag ID */
  id: Scalars["String"];
};

export type Customer = {
  __typename?: "Customer";
  /** Missing Transport tag count */
  _count: CustomerCount;
  address: Scalars["String"];
  /**
   * Comment for id field
   * Second comment for id field
   */
  code: Scalars["String"];
  createdAt: Scalars["DateTime"];
  /** List delivery locations related to current customer */
  deliveryLocations: Array<DeliveryLocation>;
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  name: Scalars["String"];
  /** List orders related to current customer */
  orders: Array<Order>;
  phone: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type CustomerCount = {
  __typename?: "CustomerCount";
  deliveryLocations: Scalars["Int"];
  deliveryNote: Scalars["Int"];
  orders: Scalars["Int"];
  transportTag: Scalars["Int"];
};

export type CustomerCreateInput = {
  address?: InputMaybe<Scalars["String"]>;
  code: Scalars["String"];
  deliveryNote?: InputMaybe<DeliveryNoteCreateNestedManyWithoutCustomerInput>;
  name: Scalars["String"];
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phone?: InputMaybe<Scalars["String"]>;
  transportTag?: InputMaybe<TransportTagCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateNestedOneWithoutDeliveryLocationsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
};

export type CustomerCreateNestedOneWithoutDeliveryNoteInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
};

export type CustomerCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
};

export type CustomerCreateNestedOneWithoutTransportTagInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
};

export type CustomerCreateOrConnectWithoutDeliveryLocationsInput = {
  create: CustomerCreateWithoutDeliveryLocationsInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutDeliveryNoteInput = {
  create: CustomerCreateWithoutDeliveryNoteInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutOrdersInput = {
  create: CustomerCreateWithoutOrdersInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateWithoutDeliveryLocationsInput = {
  address?: InputMaybe<Scalars["String"]>;
  code: Scalars["String"];
  deliveryNote?: InputMaybe<DeliveryNoteCreateNestedManyWithoutCustomerInput>;
  name: Scalars["String"];
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phone?: InputMaybe<Scalars["String"]>;
  transportTag?: InputMaybe<TransportTagCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutDeliveryNoteInput = {
  address?: InputMaybe<Scalars["String"]>;
  code: Scalars["String"];
  name: Scalars["String"];
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phone?: InputMaybe<Scalars["String"]>;
  transportTag?: InputMaybe<TransportTagCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutOrdersInput = {
  address?: InputMaybe<Scalars["String"]>;
  code: Scalars["String"];
  deliveryNote?: InputMaybe<DeliveryNoteCreateNestedManyWithoutCustomerInput>;
  name: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  transportTag?: InputMaybe<TransportTagCreateNestedManyWithoutCustomerInput>;
};

export type CustomerOrderByWithRelationInput = {
  address?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deliveryLocations?: InputMaybe<DeliveryLocationOrderByRelationAggregateInput>;
  deliveryNote?: InputMaybe<DeliveryNoteOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  phone?: InputMaybe<SortOrder>;
  transportTag?: InputMaybe<TransportTagOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CustomerRelationFilter = {
  is?: InputMaybe<CustomerWhereInput>;
  isNot?: InputMaybe<CustomerWhereInput>;
};

export enum CustomerScalarFieldEnum {
  Address = "address",
  Code = "code",
  CreatedAt = "createdAt",
  Id = "id",
  IsDeleted = "isDeleted",
  Name = "name",
  Phone = "phone",
  UpdatedAt = "updatedAt",
}

export type CustomerUpdateInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  deliveryNote?: InputMaybe<DeliveryNoteUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phone?: InputMaybe<StringFieldUpdateOperationsInput>;
  transportTag?: InputMaybe<TransportTagUpdateManyWithoutCustomerNestedInput>;
};

export type CustomerUpdateOneRequiredWithoutDeliveryLocationsNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDeliveryLocationsInput>;
  create?: InputMaybe<CustomerCreateWithoutDeliveryLocationsInput>;
  update?: InputMaybe<CustomerUpdateWithoutDeliveryLocationsInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutDeliveryLocationsInput>;
};

export type CustomerUpdateOneRequiredWithoutDeliveryNoteNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDeliveryNoteInput>;
  create?: InputMaybe<CustomerCreateWithoutDeliveryNoteInput>;
  update?: InputMaybe<CustomerUpdateWithoutDeliveryNoteInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutDeliveryNoteInput>;
};

export type CustomerUpdateOneRequiredWithoutOrdersNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<CustomerCreateWithoutOrdersInput>;
  update?: InputMaybe<CustomerUpdateWithoutOrdersInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutOrdersInput>;
};

export type CustomerUpdateWithoutDeliveryLocationsInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  deliveryNote?: InputMaybe<DeliveryNoteUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phone?: InputMaybe<StringFieldUpdateOperationsInput>;
  transportTag?: InputMaybe<TransportTagUpdateManyWithoutCustomerNestedInput>;
};

export type CustomerUpdateWithoutDeliveryNoteInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phone?: InputMaybe<StringFieldUpdateOperationsInput>;
  transportTag?: InputMaybe<TransportTagUpdateManyWithoutCustomerNestedInput>;
};

export type CustomerUpdateWithoutOrdersInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  deliveryNote?: InputMaybe<DeliveryNoteUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<StringFieldUpdateOperationsInput>;
  transportTag?: InputMaybe<TransportTagUpdateManyWithoutCustomerNestedInput>;
};

export type CustomerUpsertWithoutDeliveryLocationsInput = {
  create: CustomerCreateWithoutDeliveryLocationsInput;
  update: CustomerUpdateWithoutDeliveryLocationsInput;
};

export type CustomerUpsertWithoutDeliveryNoteInput = {
  create: CustomerCreateWithoutDeliveryNoteInput;
  update: CustomerUpdateWithoutDeliveryNoteInput;
};

export type CustomerUpsertWithoutOrdersInput = {
  create: CustomerCreateWithoutOrdersInput;
  update: CustomerUpdateWithoutOrdersInput;
};

export type CustomerWhereInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  address?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deliveryLocations?: InputMaybe<DeliveryLocationListRelationFilter>;
  deliveryNote?: InputMaybe<DeliveryNoteListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  phone?: InputMaybe<StringFilter>;
  transportTag?: InputMaybe<TransportTagListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerWhereUniqueInput = {
  code?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type DecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Decimal"]>;
  divide?: InputMaybe<Scalars["Decimal"]>;
  increment?: InputMaybe<Scalars["Decimal"]>;
  multiply?: InputMaybe<Scalars["Decimal"]>;
  set?: InputMaybe<Scalars["Decimal"]>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars["Decimal"]>;
  gt?: InputMaybe<Scalars["Decimal"]>;
  gte?: InputMaybe<Scalars["Decimal"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]>>;
  lt?: InputMaybe<Scalars["Decimal"]>;
  lte?: InputMaybe<Scalars["Decimal"]>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars["Decimal"]>;
  gt?: InputMaybe<Scalars["Decimal"]>;
  gte?: InputMaybe<Scalars["Decimal"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]>>;
  lt?: InputMaybe<Scalars["Decimal"]>;
  lte?: InputMaybe<Scalars["Decimal"]>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type DeliveryLocation = {
  __typename?: "DeliveryLocation";
  _count: DeliveryLocationCount;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["String"];
  id: Scalars["ID"];
  lat: Scalars["Decimal"];
  lon: Scalars["Decimal"];
  placeName: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type DeliveryLocationCount = {
  __typename?: "DeliveryLocationCount";
  TransportTag: Scalars["Int"];
  orders: Scalars["Int"];
};

export type DeliveryLocationCreateInput = {
  TransportTag?: InputMaybe<TransportTagCreateNestedManyWithoutDeliveryLocationInput>;
  customer: CustomerCreateNestedOneWithoutDeliveryLocationsInput;
  lat?: InputMaybe<Scalars["Decimal"]>;
  lon?: InputMaybe<Scalars["Decimal"]>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutDeliveryLocationInput>;
  placeName?: InputMaybe<Scalars["String"]>;
};

export type DeliveryLocationCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<DeliveryLocationWhereUniqueInput>;
};

export type DeliveryLocationCreateNestedOneWithoutTransportTagInput = {
  connect?: InputMaybe<DeliveryLocationWhereUniqueInput>;
};

export type DeliveryLocationCreateOrConnectWithoutOrdersInput = {
  create: DeliveryLocationCreateWithoutOrdersInput;
  where: DeliveryLocationWhereUniqueInput;
};

export type DeliveryLocationCreateWithoutOrdersInput = {
  TransportTag?: InputMaybe<TransportTagCreateNestedManyWithoutDeliveryLocationInput>;
  customer: CustomerCreateNestedOneWithoutDeliveryLocationsInput;
  lat?: InputMaybe<Scalars["Decimal"]>;
  lon?: InputMaybe<Scalars["Decimal"]>;
  placeName?: InputMaybe<Scalars["String"]>;
};

export type DeliveryLocationListRelationFilter = {
  every?: InputMaybe<DeliveryLocationWhereInput>;
  none?: InputMaybe<DeliveryLocationWhereInput>;
  some?: InputMaybe<DeliveryLocationWhereInput>;
};

export type DeliveryLocationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DeliveryLocationOrderByWithRelationInput = {
  TransportTag?: InputMaybe<TransportTagOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  lat?: InputMaybe<SortOrder>;
  lon?: InputMaybe<SortOrder>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  placeName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DeliveryLocationRelationFilter = {
  is?: InputMaybe<DeliveryLocationWhereInput>;
  isNot?: InputMaybe<DeliveryLocationWhereInput>;
};

export enum DeliveryLocationScalarFieldEnum {
  CreatedAt = "createdAt",
  CustomerId = "customerId",
  Id = "id",
  Lat = "lat",
  Lon = "lon",
  PlaceName = "placeName",
  UpdatedAt = "updatedAt",
}

export type DeliveryLocationUpdateInput = {
  TransportTag?: InputMaybe<TransportTagUpdateManyWithoutDeliveryLocationNestedInput>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutDeliveryLocationsNestedInput>;
  lat?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  lon?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutDeliveryLocationNestedInput>;
  placeName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DeliveryLocationUpdateOneRequiredWithoutOrdersNestedInput = {
  connect?: InputMaybe<DeliveryLocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeliveryLocationCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<DeliveryLocationCreateWithoutOrdersInput>;
  update?: InputMaybe<DeliveryLocationUpdateWithoutOrdersInput>;
  upsert?: InputMaybe<DeliveryLocationUpsertWithoutOrdersInput>;
};

export type DeliveryLocationUpdateWithoutOrdersInput = {
  TransportTag?: InputMaybe<TransportTagUpdateManyWithoutDeliveryLocationNestedInput>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutDeliveryLocationsNestedInput>;
  lat?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  lon?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  placeName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DeliveryLocationUpsertWithoutOrdersInput = {
  create: DeliveryLocationCreateWithoutOrdersInput;
  update: DeliveryLocationUpdateWithoutOrdersInput;
};

export type DeliveryLocationWhereInput = {
  AND?: InputMaybe<Array<DeliveryLocationWhereInput>>;
  NOT?: InputMaybe<Array<DeliveryLocationWhereInput>>;
  OR?: InputMaybe<Array<DeliveryLocationWhereInput>>;
  TransportTag?: InputMaybe<TransportTagListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  lat?: InputMaybe<DecimalFilter>;
  lon?: InputMaybe<DecimalFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  placeName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DeliveryLocationWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type DeliveryNote = {
  __typename?: "DeliveryNote";
  createByUserId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["String"];
  deliveryAddress: Scalars["String"];
  documentNo: Scalars["String"];
  id: Scalars["ID"];
  line1_text: Scalars["String"];
  line1_value: Scalars["Int"];
  line2_text: Scalars["String"];
  line2_value: Scalars["Int"];
  line3_text: Scalars["String"];
  line3_value: Scalars["Int"];
  line4_text: Scalars["String"];
  line4_value: Scalars["Int"];
  line5_text: Scalars["String"];
  line5_value: Scalars["Int"];
  line6_text: Scalars["String"];
  line6_value: Scalars["Int"];
  line7_text: Scalars["String"];
  line7_value: Scalars["Int"];
  line8_text: Scalars["String"];
  line8_value: Scalars["Int"];
  updateByUserId: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type DeliveryNoteCreateInput = {
  customer: CustomerCreateNestedOneWithoutDeliveryNoteInput;
  deliveryAddress?: InputMaybe<Scalars["String"]>;
  line1_text?: InputMaybe<Scalars["String"]>;
  line1_value?: InputMaybe<Scalars["Int"]>;
  line2_text?: InputMaybe<Scalars["String"]>;
  line2_value?: InputMaybe<Scalars["Int"]>;
  line3_text?: InputMaybe<Scalars["String"]>;
  line3_value?: InputMaybe<Scalars["Int"]>;
  line4_text?: InputMaybe<Scalars["String"]>;
  line4_value?: InputMaybe<Scalars["Int"]>;
  line5_text?: InputMaybe<Scalars["String"]>;
  line5_value?: InputMaybe<Scalars["Int"]>;
  line6_text?: InputMaybe<Scalars["String"]>;
  line6_value?: InputMaybe<Scalars["Int"]>;
  line7_text?: InputMaybe<Scalars["String"]>;
  line7_value?: InputMaybe<Scalars["Int"]>;
  line8_text?: InputMaybe<Scalars["String"]>;
  line8_value?: InputMaybe<Scalars["Int"]>;
};

export type DeliveryNoteCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<DeliveryNoteWhereUniqueInput>>;
};

export type DeliveryNoteCreateWithoutCustomerInput = {
  deliveryAddress?: InputMaybe<Scalars["String"]>;
  line1_text?: InputMaybe<Scalars["String"]>;
  line1_value?: InputMaybe<Scalars["Int"]>;
  line2_text?: InputMaybe<Scalars["String"]>;
  line2_value?: InputMaybe<Scalars["Int"]>;
  line3_text?: InputMaybe<Scalars["String"]>;
  line3_value?: InputMaybe<Scalars["Int"]>;
  line4_text?: InputMaybe<Scalars["String"]>;
  line4_value?: InputMaybe<Scalars["Int"]>;
  line5_text?: InputMaybe<Scalars["String"]>;
  line5_value?: InputMaybe<Scalars["Int"]>;
  line6_text?: InputMaybe<Scalars["String"]>;
  line6_value?: InputMaybe<Scalars["Int"]>;
  line7_text?: InputMaybe<Scalars["String"]>;
  line7_value?: InputMaybe<Scalars["Int"]>;
  line8_text?: InputMaybe<Scalars["String"]>;
  line8_value?: InputMaybe<Scalars["Int"]>;
};

export type DeliveryNoteListRelationFilter = {
  every?: InputMaybe<DeliveryNoteWhereInput>;
  none?: InputMaybe<DeliveryNoteWhereInput>;
  some?: InputMaybe<DeliveryNoteWhereInput>;
};

export type DeliveryNoteOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DeliveryNoteOrderByWithRelationInput = {
  createByUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  deliveryAddress?: InputMaybe<SortOrder>;
  documentNo?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  line1_text?: InputMaybe<SortOrder>;
  line1_value?: InputMaybe<SortOrder>;
  line2_text?: InputMaybe<SortOrder>;
  line2_value?: InputMaybe<SortOrder>;
  line3_text?: InputMaybe<SortOrder>;
  line3_value?: InputMaybe<SortOrder>;
  line4_text?: InputMaybe<SortOrder>;
  line4_value?: InputMaybe<SortOrder>;
  line5_text?: InputMaybe<SortOrder>;
  line5_value?: InputMaybe<SortOrder>;
  line6_text?: InputMaybe<SortOrder>;
  line6_value?: InputMaybe<SortOrder>;
  line7_text?: InputMaybe<SortOrder>;
  line7_value?: InputMaybe<SortOrder>;
  line8_text?: InputMaybe<SortOrder>;
  line8_value?: InputMaybe<SortOrder>;
  updateByUserId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum DeliveryNoteScalarFieldEnum {
  CreateByUserId = "createByUserId",
  CreatedAt = "createdAt",
  CustomerId = "customerId",
  DeliveryAddress = "deliveryAddress",
  DocumentNo = "documentNo",
  Id = "id",
  Line1Text = "line1_text",
  Line1Value = "line1_value",
  Line2Text = "line2_text",
  Line2Value = "line2_value",
  Line3Text = "line3_text",
  Line3Value = "line3_value",
  Line4Text = "line4_text",
  Line4Value = "line4_value",
  Line5Text = "line5_text",
  Line5Value = "line5_value",
  Line6Text = "line6_text",
  Line6Value = "line6_value",
  Line7Text = "line7_text",
  Line7Value = "line7_value",
  Line8Text = "line8_text",
  Line8Value = "line8_value",
  UpdateByUserId = "updateByUserId",
  UpdatedAt = "updatedAt",
}

export type DeliveryNoteUpdateInput = {
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutDeliveryNoteNestedInput>;
  deliveryAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  line1_text?: InputMaybe<StringFieldUpdateOperationsInput>;
  line1_value?: InputMaybe<IntFieldUpdateOperationsInput>;
  line2_text?: InputMaybe<StringFieldUpdateOperationsInput>;
  line2_value?: InputMaybe<IntFieldUpdateOperationsInput>;
  line3_text?: InputMaybe<StringFieldUpdateOperationsInput>;
  line3_value?: InputMaybe<IntFieldUpdateOperationsInput>;
  line4_text?: InputMaybe<StringFieldUpdateOperationsInput>;
  line4_value?: InputMaybe<IntFieldUpdateOperationsInput>;
  line5_text?: InputMaybe<StringFieldUpdateOperationsInput>;
  line5_value?: InputMaybe<IntFieldUpdateOperationsInput>;
  line6_text?: InputMaybe<StringFieldUpdateOperationsInput>;
  line6_value?: InputMaybe<IntFieldUpdateOperationsInput>;
  line7_text?: InputMaybe<StringFieldUpdateOperationsInput>;
  line7_value?: InputMaybe<IntFieldUpdateOperationsInput>;
  line8_text?: InputMaybe<StringFieldUpdateOperationsInput>;
  line8_value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type DeliveryNoteUpdateManyWithoutCustomerNestedInput = {
  create?: InputMaybe<Array<DeliveryNoteCreateWithoutCustomerInput>>;
  delete?: InputMaybe<Array<DeliveryNoteWhereUniqueInput>>;
};

export type DeliveryNoteWhereInput = {
  AND?: InputMaybe<Array<DeliveryNoteWhereInput>>;
  NOT?: InputMaybe<Array<DeliveryNoteWhereInput>>;
  OR?: InputMaybe<Array<DeliveryNoteWhereInput>>;
  createByUserId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<UuidFilter>;
  deliveryAddress?: InputMaybe<StringFilter>;
  documentNo?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  line1_text?: InputMaybe<StringFilter>;
  line1_value?: InputMaybe<IntFilter>;
  line2_text?: InputMaybe<StringFilter>;
  line2_value?: InputMaybe<IntFilter>;
  line3_text?: InputMaybe<StringFilter>;
  line3_value?: InputMaybe<IntFilter>;
  line4_text?: InputMaybe<StringFilter>;
  line4_value?: InputMaybe<IntFilter>;
  line5_text?: InputMaybe<StringFilter>;
  line5_value?: InputMaybe<IntFilter>;
  line6_text?: InputMaybe<StringFilter>;
  line6_value?: InputMaybe<IntFilter>;
  line7_text?: InputMaybe<StringFilter>;
  line7_value?: InputMaybe<IntFilter>;
  line8_text?: InputMaybe<StringFilter>;
  line8_value?: InputMaybe<IntFilter>;
  updateByUserId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DeliveryNoteWhereUniqueInput = {
  documentNo?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export type EnumActionTypeFilter = {
  equals?: InputMaybe<ActionType>;
  in?: InputMaybe<Array<ActionType>>;
  not?: InputMaybe<NestedEnumActionTypeFilter>;
  notIn?: InputMaybe<Array<ActionType>>;
};

export type EnumCoatingTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CoatingType>;
};

export type EnumCoatingTypeFilter = {
  equals?: InputMaybe<CoatingType>;
  in?: InputMaybe<Array<CoatingType>>;
  not?: InputMaybe<NestedEnumCoatingTypeFilter>;
  notIn?: InputMaybe<Array<CoatingType>>;
};

export type EnumJobLogActionFilter = {
  equals?: InputMaybe<JobLogAction>;
  in?: InputMaybe<Array<JobLogAction>>;
  not?: InputMaybe<NestedEnumJobLogActionFilter>;
  notIn?: InputMaybe<Array<JobLogAction>>;
};

export type EnumJobSizeUnitFieldUpdateOperationsInput = {
  set?: InputMaybe<JobSizeUnit>;
};

export type EnumJobSizeUnitFilter = {
  equals?: InputMaybe<JobSizeUnit>;
  in?: InputMaybe<Array<JobSizeUnit>>;
  not?: InputMaybe<NestedEnumJobSizeUnitFilter>;
  notIn?: InputMaybe<Array<JobSizeUnit>>;
};

export type EnumJobTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<JobType>;
};

export type EnumJobTypeFilter = {
  equals?: InputMaybe<JobType>;
  in?: InputMaybe<Array<JobType>>;
  not?: InputMaybe<NestedEnumJobTypeFilter>;
  notIn?: InputMaybe<Array<JobType>>;
};

export type EnumOrderStatusTypeFilter = {
  equals?: InputMaybe<OrderStatusType>;
  in?: InputMaybe<Array<OrderStatusType>>;
  not?: InputMaybe<NestedEnumOrderStatusTypeFilter>;
  notIn?: InputMaybe<Array<OrderStatusType>>;
};

export type EnumSpottingBlockTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<SpottingBlockType>;
};

export type EnumSpottingBlockTypeFilter = {
  equals?: InputMaybe<SpottingBlockType>;
  in?: InputMaybe<Array<SpottingBlockType>>;
  not?: InputMaybe<NestedEnumSpottingBlockTypeFilter>;
  notIn?: InputMaybe<Array<SpottingBlockType>>;
};

export type EnumSpottingTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<SpottingType>;
};

export type EnumSpottingTypeFilter = {
  equals?: InputMaybe<SpottingType>;
  in?: InputMaybe<Array<SpottingType>>;
  not?: InputMaybe<NestedEnumSpottingTypeFilter>;
  notIn?: InputMaybe<Array<SpottingType>>;
};

export type EnumTransportTagStatusTypeFilter = {
  equals?: InputMaybe<TransportTagStatusType>;
  in?: InputMaybe<Array<TransportTagStatusType>>;
  not?: InputMaybe<NestedEnumTransportTagStatusTypeFilter>;
  notIn?: InputMaybe<Array<TransportTagStatusType>>;
};

export type GqlTokens = {
  __typename?: "GqlTokens";
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
};

export type History = {
  __typename?: "History";
  actionTable: Scalars["String"];
  actionType: ActionType;
  createdAt: Scalars["DateTime"];
  detail: Scalars["String"];
  id: Scalars["ID"];
  rowId?: Maybe<Scalars["String"]>;
  user: User;
  userId: Scalars["String"];
};

export type HistoryOrderByWithRelationInput = {
  actionTable?: InputMaybe<SortOrder>;
  actionType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  detail?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rowId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
};

export enum HistoryScalarFieldEnum {
  ActionTable = "actionTable",
  ActionType = "actionType",
  CreatedAt = "createdAt",
  Detail = "detail",
  Id = "id",
  RowId = "rowId",
  UserId = "userId",
}

export type HistoryWhereInput = {
  AND?: InputMaybe<Array<HistoryWhereInput>>;
  NOT?: InputMaybe<Array<HistoryWhereInput>>;
  OR?: InputMaybe<Array<HistoryWhereInput>>;
  actionTable?: InputMaybe<StringFilter>;
  actionType?: InputMaybe<EnumActionTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  detail?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  rowId?: InputMaybe<UuidNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
};

export type HistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Int"]>;
  divide?: InputMaybe<Scalars["Int"]>;
  increment?: InputMaybe<Scalars["Int"]>;
  multiply?: InputMaybe<Scalars["Int"]>;
  set?: InputMaybe<Scalars["Int"]>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type Job = {
  __typename?: "Job";
  _count: JobCount;
  coatingGap: Scalars["Int"];
  coatingProgressAmount: Scalars["Int"];
  coatingType: CoatingType;
  coatingTypeDescription: Scalars["String"];
  coatingWasteAmount: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  documentNo: Scalars["String"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  jobDate: Scalars["DateTime"];
  jobDepartment: Scalars["String"];
  jobLogs: Array<JobLog>;
  jobPages: Scalars["Int"];
  jobRemark: Scalars["String"];
  jobType: JobType;
  order: Order;
  orderId: Scalars["String"];
  page1Progress: JobProgressSummary;
  page2Progress: JobProgressSummary;
  processCount: Scalars["Int"];
  spottingBlock: SpottingBlockType;
  spottingProgressAmount: Scalars["Int"];
  spottingType: SpottingType;
  spottingTypeDescription: Scalars["String"];
  spottingWasteAmount: Scalars["Int"];
  startCount: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

export type JobCount = {
  __typename?: "JobCount";
  jobLogs: Scalars["Int"];
};

export type JobCreateInput = {
  coatingGap?: InputMaybe<Scalars["Int"]>;
  coatingProgressAmount?: InputMaybe<Scalars["Int"]>;
  coatingType?: InputMaybe<CoatingType>;
  coatingTypeDescription?: InputMaybe<Scalars["String"]>;
  coatingWasteAmount?: InputMaybe<Scalars["Int"]>;
  jobLogs?: InputMaybe<JobLogCreateNestedManyWithoutJobInput>;
  jobPages?: InputMaybe<Scalars["Int"]>;
  jobRemark?: InputMaybe<Scalars["String"]>;
  jobType: JobType;
  order: OrderCreateNestedOneWithoutJobsInput;
  processCount?: InputMaybe<Scalars["Int"]>;
  spottingBlock?: InputMaybe<SpottingBlockType>;
  spottingProgressAmount?: InputMaybe<Scalars["Int"]>;
  spottingType?: InputMaybe<SpottingType>;
  spottingTypeDescription?: InputMaybe<Scalars["String"]>;
  spottingWasteAmount?: InputMaybe<Scalars["Int"]>;
  startCount?: InputMaybe<Scalars["Int"]>;
};

export type JobCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<JobWhereUniqueInput>>;
};

export type JobCreateNestedOneWithoutJobLogsInput = {
  connect?: InputMaybe<JobWhereUniqueInput>;
};

export type JobCreateWithoutOrderInput = {
  coatingGap?: InputMaybe<Scalars["Int"]>;
  coatingProgressAmount?: InputMaybe<Scalars["Int"]>;
  coatingType?: InputMaybe<CoatingType>;
  coatingTypeDescription?: InputMaybe<Scalars["String"]>;
  coatingWasteAmount?: InputMaybe<Scalars["Int"]>;
  jobLogs?: InputMaybe<JobLogCreateNestedManyWithoutJobInput>;
  jobPages?: InputMaybe<Scalars["Int"]>;
  jobRemark?: InputMaybe<Scalars["String"]>;
  jobType: JobType;
  processCount?: InputMaybe<Scalars["Int"]>;
  spottingBlock?: InputMaybe<SpottingBlockType>;
  spottingProgressAmount?: InputMaybe<Scalars["Int"]>;
  spottingType?: InputMaybe<SpottingType>;
  spottingTypeDescription?: InputMaybe<Scalars["String"]>;
  spottingWasteAmount?: InputMaybe<Scalars["Int"]>;
  startCount?: InputMaybe<Scalars["Int"]>;
};

export type JobListRelationFilter = {
  every?: InputMaybe<JobWhereInput>;
  none?: InputMaybe<JobWhereInput>;
  some?: InputMaybe<JobWhereInput>;
};

export type JobLog = {
  __typename?: "JobLog";
  JobLogAttachments: Array<JobLogAttachment>;
  _count: JobLogCount;
  action: JobLogAction;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  job: Job;
  jobId: Scalars["String"];
  logRemark: Scalars["String"];
  progressAmount: Scalars["Int"];
  progressPage: Scalars["Int"];
  user: User;
  userId: Scalars["String"];
  wasteAmount: Scalars["Int"];
};

export enum JobLogAction {
  Progress = "PROGRESS",
  Start = "START",
}

export type JobLogAttachment = {
  __typename?: "JobLogAttachment";
  createdAt: Scalars["DateTime"];
  fileName: Scalars["String"];
  id: Scalars["ID"];
  jobLog: JobLog;
  jobLogId: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type JobLogAttachmentCreateNestedManyWithoutJobLogInput = {
  connect?: InputMaybe<Array<JobLogAttachmentWhereUniqueInput>>;
};

export type JobLogAttachmentListRelationFilter = {
  every?: InputMaybe<JobLogAttachmentWhereInput>;
  none?: InputMaybe<JobLogAttachmentWhereInput>;
  some?: InputMaybe<JobLogAttachmentWhereInput>;
};

export type JobLogAttachmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type JobLogAttachmentWhereInput = {
  AND?: InputMaybe<Array<JobLogAttachmentWhereInput>>;
  NOT?: InputMaybe<Array<JobLogAttachmentWhereInput>>;
  OR?: InputMaybe<Array<JobLogAttachmentWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fileName?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  jobLog?: InputMaybe<JobLogRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type JobLogAttachmentWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type JobLogCount = {
  __typename?: "JobLogCount";
  JobLogAttachments: Scalars["Int"];
};

export type JobLogCreateInput = {
  JobLogAttachments?: InputMaybe<JobLogAttachmentCreateNestedManyWithoutJobLogInput>;
  action?: InputMaybe<JobLogAction>;
  job: JobCreateNestedOneWithoutJobLogsInput;
  progressAmount: Scalars["Int"];
  progressPage?: InputMaybe<Scalars["Int"]>;
  wasteAmount: Scalars["Int"];
};

export type JobLogCreateNestedManyWithoutJobInput = {
  connect?: InputMaybe<Array<JobLogWhereUniqueInput>>;
};

export type JobLogCreateWithoutJobInput = {
  JobLogAttachments?: InputMaybe<JobLogAttachmentCreateNestedManyWithoutJobLogInput>;
  action?: InputMaybe<JobLogAction>;
  progressAmount: Scalars["Int"];
  progressPage?: InputMaybe<Scalars["Int"]>;
  wasteAmount: Scalars["Int"];
};

export type JobLogListRelationFilter = {
  every?: InputMaybe<JobLogWhereInput>;
  none?: InputMaybe<JobLogWhereInput>;
  some?: InputMaybe<JobLogWhereInput>;
};

export type JobLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type JobLogOrderByWithRelationInput = {
  JobLogAttachments?: InputMaybe<JobLogAttachmentOrderByRelationAggregateInput>;
  action?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  job?: InputMaybe<JobOrderByWithRelationInput>;
  progressAmount?: InputMaybe<SortOrder>;
  progressPage?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  wasteAmount?: InputMaybe<SortOrder>;
};

export type JobLogRelationFilter = {
  is?: InputMaybe<JobLogWhereInput>;
  isNot?: InputMaybe<JobLogWhereInput>;
};

export enum JobLogScalarFieldEnum {
  Action = "action",
  CreatedAt = "createdAt",
  Id = "id",
  JobId = "jobId",
  LogRemark = "logRemark",
  ProgressAmount = "progressAmount",
  ProgressPage = "progressPage",
  UserId = "userId",
  WasteAmount = "wasteAmount",
}

export type JobLogUpdateManyWithoutJobNestedInput = {
  create?: InputMaybe<Array<JobLogCreateWithoutJobInput>>;
  delete?: InputMaybe<Array<JobLogWhereUniqueInput>>;
};

export type JobLogWhereInput = {
  AND?: InputMaybe<Array<JobLogWhereInput>>;
  JobLogAttachments?: InputMaybe<JobLogAttachmentListRelationFilter>;
  NOT?: InputMaybe<Array<JobLogWhereInput>>;
  OR?: InputMaybe<Array<JobLogWhereInput>>;
  action?: InputMaybe<EnumJobLogActionFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  job?: InputMaybe<JobRelationFilter>;
  progressAmount?: InputMaybe<IntFilter>;
  progressPage?: InputMaybe<IntFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
  wasteAmount?: InputMaybe<IntFilter>;
};

export type JobLogWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type JobOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type JobOrderByWithRelationInput = {
  coatingGap?: InputMaybe<SortOrder>;
  coatingProgressAmount?: InputMaybe<SortOrder>;
  coatingType?: InputMaybe<SortOrder>;
  coatingTypeDescription?: InputMaybe<SortOrder>;
  coatingWasteAmount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  documentNo?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobDate?: InputMaybe<SortOrder>;
  jobLogs?: InputMaybe<JobLogOrderByRelationAggregateInput>;
  jobPages?: InputMaybe<SortOrder>;
  jobRemark?: InputMaybe<SortOrder>;
  jobType?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  processCount?: InputMaybe<SortOrder>;
  spottingBlock?: InputMaybe<SortOrder>;
  spottingProgressAmount?: InputMaybe<SortOrder>;
  spottingType?: InputMaybe<SortOrder>;
  spottingTypeDescription?: InputMaybe<SortOrder>;
  spottingWasteAmount?: InputMaybe<SortOrder>;
  startCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type JobProgressSummary = {
  __typename?: "JobProgressSummary";
  progressAmount?: Maybe<Scalars["Float"]>;
  wasteAmount?: Maybe<Scalars["Float"]>;
};

export type JobRelationFilter = {
  is?: InputMaybe<JobWhereInput>;
  isNot?: InputMaybe<JobWhereInput>;
};

export enum JobScalarFieldEnum {
  CoatingGap = "coatingGap",
  CoatingProgressAmount = "coatingProgressAmount",
  CoatingType = "coatingType",
  CoatingTypeDescription = "coatingTypeDescription",
  CoatingWasteAmount = "coatingWasteAmount",
  CreatedAt = "createdAt",
  DocumentNo = "documentNo",
  Id = "id",
  IsDeleted = "isDeleted",
  JobDate = "jobDate",
  JobPages = "jobPages",
  JobRemark = "jobRemark",
  JobType = "jobType",
  OrderId = "orderId",
  ProcessCount = "processCount",
  SpottingBlock = "spottingBlock",
  SpottingProgressAmount = "spottingProgressAmount",
  SpottingType = "spottingType",
  SpottingTypeDescription = "spottingTypeDescription",
  SpottingWasteAmount = "spottingWasteAmount",
  StartCount = "startCount",
  UpdatedAt = "updatedAt",
}

export enum JobSizeUnit {
  Cm = "CM",
  Inch = "INCH",
}

export enum JobType {
  Coating = "COATING",
  Spot = "SPOT",
}

export type JobUpdateInput = {
  coatingGap?: InputMaybe<IntFieldUpdateOperationsInput>;
  coatingProgressAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  coatingType?: InputMaybe<EnumCoatingTypeFieldUpdateOperationsInput>;
  coatingTypeDescription?: InputMaybe<StringFieldUpdateOperationsInput>;
  coatingWasteAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  jobLogs?: InputMaybe<JobLogUpdateManyWithoutJobNestedInput>;
  jobPages?: InputMaybe<IntFieldUpdateOperationsInput>;
  jobRemark?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobType?: InputMaybe<EnumJobTypeFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutJobsNestedInput>;
  processCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  spottingBlock?: InputMaybe<EnumSpottingBlockTypeFieldUpdateOperationsInput>;
  spottingProgressAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  spottingType?: InputMaybe<EnumSpottingTypeFieldUpdateOperationsInput>;
  spottingTypeDescription?: InputMaybe<StringFieldUpdateOperationsInput>;
  spottingWasteAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  startCount?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type JobUpdateManyWithoutOrderNestedInput = {
  create?: InputMaybe<Array<JobCreateWithoutOrderInput>>;
  delete?: InputMaybe<Array<JobWhereUniqueInput>>;
};

export type JobWhereInput = {
  AND?: InputMaybe<Array<JobWhereInput>>;
  NOT?: InputMaybe<Array<JobWhereInput>>;
  OR?: InputMaybe<Array<JobWhereInput>>;
  coatingGap?: InputMaybe<IntFilter>;
  coatingProgressAmount?: InputMaybe<IntFilter>;
  coatingType?: InputMaybe<EnumCoatingTypeFilter>;
  coatingTypeDescription?: InputMaybe<StringFilter>;
  coatingWasteAmount?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  documentNo?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  jobDate?: InputMaybe<DateTimeFilter>;
  jobLogs?: InputMaybe<JobLogListRelationFilter>;
  jobPages?: InputMaybe<IntFilter>;
  jobRemark?: InputMaybe<StringFilter>;
  jobType?: InputMaybe<EnumJobTypeFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  processCount?: InputMaybe<IntFilter>;
  spottingBlock?: InputMaybe<EnumSpottingBlockTypeFilter>;
  spottingProgressAmount?: InputMaybe<IntFilter>;
  spottingType?: InputMaybe<EnumSpottingTypeFilter>;
  spottingTypeDescription?: InputMaybe<StringFilter>;
  spottingWasteAmount?: InputMaybe<IntFilter>;
  startCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type JobWhereUniqueInput = {
  documentNo?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export type LineUser = {
  __typename?: "LineUser";
  createdAt: Scalars["DateTime"];
  displayName: Scalars["String"];
  id: Scalars["ID"];
  isFriendship: Scalars["Boolean"];
  pictureUrl: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  userId?: Maybe<Scalars["String"]>;
};

export type LineUserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isFriendship?: InputMaybe<SortOrder>;
  pictureUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
};

export type LineUserRelationFilter = {
  is?: InputMaybe<LineUserWhereInput>;
  isNot?: InputMaybe<LineUserWhereInput>;
};

export type LineUserUpdateInput = {
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  pictureUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLineUserNestedInput>;
};

export type LineUserWhereInput = {
  AND?: InputMaybe<Array<LineUserWhereInput>>;
  NOT?: InputMaybe<Array<LineUserWhereInput>>;
  OR?: InputMaybe<Array<LineUserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isFriendship?: InputMaybe<BoolFilter>;
  pictureUrl?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
};

export type Mutation = {
  __typename?: "Mutation";
  /**
   * Roles:
   *   MAIN_MASTER,
   *   USER_MASTER
   *
   */
  blockUser: User;
  /** Roles: MAIN_MASTER */
  cancelOrder: Order;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     CUSTOMER_MASTER
   *
   */
  createCustomer: Customer;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     CUSTOMER_MASTER,
   *     LOCATION_MASTER
   */
  createDeliveryLocation: DeliveryLocation;
  /**
   * Roles:
   *   MAIN_MASTER,
   *   DELIVERYNOTE_MASTER,
   *
   */
  createDeliveryNote: DeliveryNote;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     JOB_MASTER,
   *     ORDER_MASTER
   */
  createJob: Job;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     JOB_MASTER
   *
   */
  createJobLog: JobLog;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     ORDER_MASTER
   *
   */
  createOrder: Order;
  /**
   * Roles:
   *       MAIN_MASTER,
   *       TRANSPORTTAG_MASTER
   */
  createTransportTag: TransportTag;
  /**
   * Roles:
   *       MAIN_MASTER,
   *       TRANSPORTTAGLOG_MASTER
   *
   */
  createTransportTagLog: TransportTagLog;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     CUSTOMER_MASTER
   *
   */
  deleteCustomer: Customer;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     ORDER_MASTER
   *
   */
  deleteJob: Job;
  /** Roles: MAIN_MASTER, ORDER_MASTER */
  deleteJobLog: JobLog;
  /**
   * Roles:
   *       MAIN_MASTER,
   *       ORDER_MASTER
   *
   */
  deleteOrderAttachment: OrderAttachment;
  /**
   * Roles:
   *       MAIN_MASTER,
   *       TRANSPORTTAG_MASTER
   */
  deleteTransportTag: TransportTag;
  /**
   * Roles:
   *       MAIN_MASTER,
   *       ORDER_MASTER
   */
  finishOrder: Order;
  /** using refresh token in http authorization header */
  logout: GqlTokens;
  /**
   * Roles:
   *   MAIN_MASTER
   *   INVOICE_MASTER
   *
   */
  markInvoicedByJobId: Order;
  /**
   * Roles:
   *     MAIN_MASTER
   *     INVOICE_MASTER
   *
   */
  markInvoicedByOrderId: Order;
  /**
   * Roles:
   *   MAIN_MASTER
   *   INVOICE_MASTER
   *
   */
  markInvoicedByTransportTagId: Order;
  pdfDeliveryNote: Pdf;
  pdfJob: Pdf;
  pdfTransportTag: Pdf;
  /** using refresh token in http authorization header */
  refreshToken: GqlTokens;
  /** To authenticate using line access token */
  signinWithLine: GqlTokens;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     CUSTOMER_MASTER
   *
   */
  updateCustomer: Customer;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     CUSTOMER_MASTER,
   *     LOCATION_MASTER
   */
  updateDeliveryLocation: DeliveryLocation;
  /**
   * Roles:
   *   MAIN_MASTER,
   *   DELIVERYNOTE_MASTER,
   *
   */
  updateDeliveryNote: DeliveryNote;
  /**
   * Roles:
   *       MAIN_MASTER,
   *       TRANSPORTTAGLOG_MASTER
   *
   */
  updateGeoFromTransport: DeliveryLocation;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     JOB_MASTER,
   *     ORDER_MASTER
   */
  updateJob: Job;
  /** Roles: Any */
  updateLineProfile: LineUser;
  /**
   * Roles:
   *       MAIN_MASTER,
   *       ORDER_MASTER
   *
   */
  updateOrder: Order;
  /**
   * Roles:
   *       MAIN_MASTER,
   *       USER_MASTER
   *
   */
  updateUser: User;
};

export type MutationBlockUserArgs = {
  where: UserWhereUniqueInput;
};

export type MutationCancelOrderArgs = {
  data: CancelOrderInput;
  where: OrderWhereUniqueInput;
};

export type MutationCreateCustomerArgs = {
  data: CustomerCreateInput;
};

export type MutationCreateDeliveryLocationArgs = {
  data: DeliveryLocationCreateInput;
};

export type MutationCreateDeliveryNoteArgs = {
  data: DeliveryNoteCreateInput;
};

export type MutationCreateJobArgs = {
  data: JobCreateInput;
};

export type MutationCreateJobLogArgs = {
  data: JobLogCreateInput;
};

export type MutationCreateOrderArgs = {
  data: OrderCreateInput;
};

export type MutationCreateTransportTagArgs = {
  data: TransportTagCreateInput;
};

export type MutationCreateTransportTagLogArgs = {
  data: TransportTagLogCreateInput;
};

export type MutationDeleteCustomerArgs = {
  where: CustomerWhereUniqueInput;
};

export type MutationDeleteJobArgs = {
  where: JobWhereUniqueInput;
};

export type MutationDeleteJobLogArgs = {
  where: JobLogWhereUniqueInput;
};

export type MutationDeleteOrderAttachmentArgs = {
  where: OrderAttachmentWhereUniqueInput;
};

export type MutationDeleteTransportTagArgs = {
  where: TransportTagWhereUniqueInput;
};

export type MutationFinishOrderArgs = {
  where: OrderWhereUniqueInput;
};

export type MutationMarkInvoicedByJobIdArgs = {
  where: JobWhereUniqueInput;
};

export type MutationMarkInvoicedByOrderIdArgs = {
  where: OrderWhereUniqueInput;
};

export type MutationMarkInvoicedByTransportTagIdArgs = {
  where: TransportTagWhereUniqueInput;
};

export type MutationPdfDeliveryNoteArgs = {
  where: WhereIdInput;
};

export type MutationPdfJobArgs = {
  data: CreateJobPdfInput;
};

export type MutationPdfTransportTagArgs = {
  data: CreateTransportTagPdfInput;
};

export type MutationSigninWithLineArgs = {
  lineToken: Scalars["String"];
};

export type MutationUpdateCustomerArgs = {
  data: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
};

export type MutationUpdateDeliveryLocationArgs = {
  data: DeliveryLocationUpdateInput;
  where: DeliveryLocationWhereUniqueInput;
};

export type MutationUpdateDeliveryNoteArgs = {
  data: DeliveryNoteUpdateInput;
  where: DeliveryNoteWhereUniqueInput;
};

export type MutationUpdateGeoFromTransportArgs = {
  data: UpdateGeoInput;
  where: DeliveryLocationWhereUniqueInput;
};

export type MutationUpdateJobArgs = {
  data: JobUpdateInput;
  where: JobWhereUniqueInput;
};

export type MutationUpdateLineProfileArgs = {
  data: LineUserUpdateInput;
};

export type MutationUpdateOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};

export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars["Decimal"]>;
  gt?: InputMaybe<Scalars["Decimal"]>;
  gte?: InputMaybe<Scalars["Decimal"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]>>;
  lt?: InputMaybe<Scalars["Decimal"]>;
  lte?: InputMaybe<Scalars["Decimal"]>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars["Decimal"]>;
  gt?: InputMaybe<Scalars["Decimal"]>;
  gte?: InputMaybe<Scalars["Decimal"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]>>;
  lt?: InputMaybe<Scalars["Decimal"]>;
  lte?: InputMaybe<Scalars["Decimal"]>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type NestedEnumActionTypeFilter = {
  equals?: InputMaybe<ActionType>;
  in?: InputMaybe<Array<ActionType>>;
  not?: InputMaybe<NestedEnumActionTypeFilter>;
  notIn?: InputMaybe<Array<ActionType>>;
};

export type NestedEnumCoatingTypeFilter = {
  equals?: InputMaybe<CoatingType>;
  in?: InputMaybe<Array<CoatingType>>;
  not?: InputMaybe<NestedEnumCoatingTypeFilter>;
  notIn?: InputMaybe<Array<CoatingType>>;
};

export type NestedEnumJobLogActionFilter = {
  equals?: InputMaybe<JobLogAction>;
  in?: InputMaybe<Array<JobLogAction>>;
  not?: InputMaybe<NestedEnumJobLogActionFilter>;
  notIn?: InputMaybe<Array<JobLogAction>>;
};

export type NestedEnumJobSizeUnitFilter = {
  equals?: InputMaybe<JobSizeUnit>;
  in?: InputMaybe<Array<JobSizeUnit>>;
  not?: InputMaybe<NestedEnumJobSizeUnitFilter>;
  notIn?: InputMaybe<Array<JobSizeUnit>>;
};

export type NestedEnumJobTypeFilter = {
  equals?: InputMaybe<JobType>;
  in?: InputMaybe<Array<JobType>>;
  not?: InputMaybe<NestedEnumJobTypeFilter>;
  notIn?: InputMaybe<Array<JobType>>;
};

export type NestedEnumOrderStatusTypeFilter = {
  equals?: InputMaybe<OrderStatusType>;
  in?: InputMaybe<Array<OrderStatusType>>;
  not?: InputMaybe<NestedEnumOrderStatusTypeFilter>;
  notIn?: InputMaybe<Array<OrderStatusType>>;
};

export type NestedEnumSpottingBlockTypeFilter = {
  equals?: InputMaybe<SpottingBlockType>;
  in?: InputMaybe<Array<SpottingBlockType>>;
  not?: InputMaybe<NestedEnumSpottingBlockTypeFilter>;
  notIn?: InputMaybe<Array<SpottingBlockType>>;
};

export type NestedEnumSpottingTypeFilter = {
  equals?: InputMaybe<SpottingType>;
  in?: InputMaybe<Array<SpottingType>>;
  not?: InputMaybe<NestedEnumSpottingTypeFilter>;
  notIn?: InputMaybe<Array<SpottingType>>;
};

export type NestedEnumTransportTagStatusTypeFilter = {
  equals?: InputMaybe<TransportTagStatusType>;
  in?: InputMaybe<Array<TransportTagStatusType>>;
  not?: InputMaybe<NestedEnumTransportTagStatusTypeFilter>;
  notIn?: InputMaybe<Array<TransportTagStatusType>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type NestedUuidFilter = {
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type NestedUuidNullableFilter = {
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type Order = {
  __typename?: "Order";
  _count: OrderCountWithTag;
  cancelReason: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["String"];
  deliveryLocation: DeliveryLocation;
  deliveryLocationId: Scalars["String"];
  documentDate: Scalars["DateTime"];
  documentNo: Scalars["String"];
  finishedAmount: Scalars["Float"];
  hasFinalizeTag: Scalars["Boolean"];
  id: Scalars["ID"];
  invoiced: Scalars["Boolean"];
  isDeleted: Scalars["Boolean"];
  jobName: Scalars["String"];
  jobNeededAmount: Scalars["Int"];
  jobReceiveFrom: Scalars["String"];
  jobSizeHeight: Scalars["Decimal"];
  jobSizeUnit: JobSizeUnit;
  jobSizeWidth: Scalars["Decimal"];
  jobWastable: Scalars["Int"];
  jobs?: Maybe<Array<Job>>;
  latestJobUpdate?: Maybe<Scalars["DateTime"]>;
  orderAttachments: Array<OrderAttachment>;
  orderStatus: OrderStatusType;
  purchaseOrderNo: Scalars["String"];
  transportTags?: Maybe<Array<TransportTag>>;
  updatedAt: Scalars["DateTime"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]>;
};

export type OrderAttachment = {
  __typename?: "OrderAttachment";
  createdAt: Scalars["DateTime"];
  fileName: Scalars["String"];
  id: Scalars["ID"];
  order: Order;
  orderId: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type OrderAttachmentCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<OrderAttachmentWhereUniqueInput>>;
};

export type OrderAttachmentCreateWithoutOrderInput = {
  fileName: Scalars["String"];
};

export type OrderAttachmentListRelationFilter = {
  every?: InputMaybe<OrderAttachmentWhereInput>;
  none?: InputMaybe<OrderAttachmentWhereInput>;
  some?: InputMaybe<OrderAttachmentWhereInput>;
};

export type OrderAttachmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderAttachmentUpdateManyWithoutOrderNestedInput = {
  create?: InputMaybe<Array<OrderAttachmentCreateWithoutOrderInput>>;
  delete?: InputMaybe<Array<OrderAttachmentWhereUniqueInput>>;
};

export type OrderAttachmentWhereInput = {
  AND?: InputMaybe<Array<OrderAttachmentWhereInput>>;
  NOT?: InputMaybe<Array<OrderAttachmentWhereInput>>;
  OR?: InputMaybe<Array<OrderAttachmentWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fileName?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderAttachmentWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type OrderCount = {
  __typename?: "OrderCount";
  jobs: Scalars["Int"];
  orderAttachments: Scalars["Int"];
  transportTags: Scalars["Int"];
};

export type OrderCountWithTag = {
  __typename?: "OrderCountWithTag";
  jobs: Scalars["Int"];
  orderAttachments: Scalars["Int"];
  transportTags: Scalars["Int"];
};

export type OrderCreateInput = {
  customer: CustomerCreateNestedOneWithoutOrdersInput;
  deliveryLocation: DeliveryLocationCreateNestedOneWithoutOrdersInput;
  jobName?: InputMaybe<Scalars["String"]>;
  jobNeededAmount?: InputMaybe<Scalars["Int"]>;
  jobReceiveFrom?: InputMaybe<Scalars["String"]>;
  jobSizeHeight?: InputMaybe<Scalars["Decimal"]>;
  jobSizeUnit?: InputMaybe<JobSizeUnit>;
  jobSizeWidth?: InputMaybe<Scalars["Decimal"]>;
  jobWastable?: InputMaybe<Scalars["Int"]>;
  jobs?: InputMaybe<JobCreateNestedManyWithoutOrderInput>;
  orderAttachments?: InputMaybe<OrderAttachmentCreateNestedManyWithoutOrderInput>;
  purchaseOrderNo?: InputMaybe<Scalars["String"]>;
  transportTags?: InputMaybe<TransportTagCreateNestedManyWithoutOrderInput>;
  user?: InputMaybe<UserCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
};

export type OrderCreateNestedManyWithoutDeliveryLocationInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
};

export type OrderCreateNestedOneWithoutJobsInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
};

export type OrderCreateNestedOneWithoutTransportTagsInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
};

export type OrderCreateOrConnectWithoutJobsInput = {
  create: OrderCreateWithoutJobsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateWithoutCustomerInput = {
  deliveryLocation: DeliveryLocationCreateNestedOneWithoutOrdersInput;
  jobName?: InputMaybe<Scalars["String"]>;
  jobNeededAmount?: InputMaybe<Scalars["Int"]>;
  jobReceiveFrom?: InputMaybe<Scalars["String"]>;
  jobSizeHeight?: InputMaybe<Scalars["Decimal"]>;
  jobSizeUnit?: InputMaybe<JobSizeUnit>;
  jobSizeWidth?: InputMaybe<Scalars["Decimal"]>;
  jobWastable?: InputMaybe<Scalars["Int"]>;
  jobs?: InputMaybe<JobCreateNestedManyWithoutOrderInput>;
  orderAttachments?: InputMaybe<OrderAttachmentCreateNestedManyWithoutOrderInput>;
  purchaseOrderNo?: InputMaybe<Scalars["String"]>;
  transportTags?: InputMaybe<TransportTagCreateNestedManyWithoutOrderInput>;
  user?: InputMaybe<UserCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutDeliveryLocationInput = {
  customer: CustomerCreateNestedOneWithoutOrdersInput;
  jobName?: InputMaybe<Scalars["String"]>;
  jobNeededAmount?: InputMaybe<Scalars["Int"]>;
  jobReceiveFrom?: InputMaybe<Scalars["String"]>;
  jobSizeHeight?: InputMaybe<Scalars["Decimal"]>;
  jobSizeUnit?: InputMaybe<JobSizeUnit>;
  jobSizeWidth?: InputMaybe<Scalars["Decimal"]>;
  jobWastable?: InputMaybe<Scalars["Int"]>;
  jobs?: InputMaybe<JobCreateNestedManyWithoutOrderInput>;
  orderAttachments?: InputMaybe<OrderAttachmentCreateNestedManyWithoutOrderInput>;
  purchaseOrderNo?: InputMaybe<Scalars["String"]>;
  transportTags?: InputMaybe<TransportTagCreateNestedManyWithoutOrderInput>;
  user?: InputMaybe<UserCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutJobsInput = {
  customer: CustomerCreateNestedOneWithoutOrdersInput;
  deliveryLocation: DeliveryLocationCreateNestedOneWithoutOrdersInput;
  jobName?: InputMaybe<Scalars["String"]>;
  jobNeededAmount?: InputMaybe<Scalars["Int"]>;
  jobReceiveFrom?: InputMaybe<Scalars["String"]>;
  jobSizeHeight?: InputMaybe<Scalars["Decimal"]>;
  jobSizeUnit?: InputMaybe<JobSizeUnit>;
  jobSizeWidth?: InputMaybe<Scalars["Decimal"]>;
  jobWastable?: InputMaybe<Scalars["Int"]>;
  orderAttachments?: InputMaybe<OrderAttachmentCreateNestedManyWithoutOrderInput>;
  purchaseOrderNo?: InputMaybe<Scalars["String"]>;
  transportTags?: InputMaybe<TransportTagCreateNestedManyWithoutOrderInput>;
  user?: InputMaybe<UserCreateNestedOneWithoutOrderInput>;
};

export type OrderListRelationFilter = {
  every?: InputMaybe<OrderWhereInput>;
  none?: InputMaybe<OrderWhereInput>;
  some?: InputMaybe<OrderWhereInput>;
};

export type OrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithRelationInput = {
  cancelReason?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  deliveryLocation?: InputMaybe<DeliveryLocationOrderByWithRelationInput>;
  deliveryLocationId?: InputMaybe<SortOrder>;
  documentDate?: InputMaybe<SortOrder>;
  documentNo?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiced?: InputMaybe<SortOrder>;
  jobName?: InputMaybe<SortOrder>;
  jobNeededAmount?: InputMaybe<SortOrder>;
  jobReceiveFrom?: InputMaybe<SortOrder>;
  jobSizeHeight?: InputMaybe<SortOrder>;
  jobSizeUnit?: InputMaybe<SortOrder>;
  jobSizeWidth?: InputMaybe<SortOrder>;
  jobWastable?: InputMaybe<SortOrder>;
  jobs?: InputMaybe<JobOrderByRelationAggregateInput>;
  orderAttachments?: InputMaybe<OrderAttachmentOrderByRelationAggregateInput>;
  orderStatus?: InputMaybe<SortOrder>;
  purchaseOrderNo?: InputMaybe<SortOrder>;
  transportTags?: InputMaybe<TransportTagOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type OrderRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export enum OrderScalarFieldEnum {
  CancelReason = "cancelReason",
  CreatedAt = "createdAt",
  CustomerId = "customerId",
  DeliveryLocationId = "deliveryLocationId",
  DocumentDate = "documentDate",
  DocumentNo = "documentNo",
  Id = "id",
  Invoiced = "invoiced",
  IsDeleted = "isDeleted",
  JobName = "jobName",
  JobNeededAmount = "jobNeededAmount",
  JobReceiveFrom = "jobReceiveFrom",
  JobSizeHeight = "jobSizeHeight",
  JobSizeUnit = "jobSizeUnit",
  JobSizeWidth = "jobSizeWidth",
  JobWastable = "jobWastable",
  OrderStatus = "orderStatus",
  PurchaseOrderNo = "purchaseOrderNo",
  UpdatedAt = "updatedAt",
  UserId = "userId",
}

export enum OrderStatusType {
  Cancelled = "CANCELLED",
  InProgress = "IN_PROGRESS",
  JobsFinish = "JOBS_FINISH",
  New = "NEW",
}

export type OrderUpdateInput = {
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutOrdersNestedInput>;
  deliveryLocation?: InputMaybe<DeliveryLocationUpdateOneRequiredWithoutOrdersNestedInput>;
  jobName?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobNeededAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  jobReceiveFrom?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobSizeHeight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  jobSizeUnit?: InputMaybe<EnumJobSizeUnitFieldUpdateOperationsInput>;
  jobSizeWidth?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  jobWastable?: InputMaybe<IntFieldUpdateOperationsInput>;
  jobs?: InputMaybe<JobUpdateManyWithoutOrderNestedInput>;
  orderAttachments?: InputMaybe<OrderAttachmentUpdateManyWithoutOrderNestedInput>;
  purchaseOrderNo?: InputMaybe<StringFieldUpdateOperationsInput>;
  transportTags?: InputMaybe<TransportTagUpdateManyWithoutOrderNestedInput>;
  user?: InputMaybe<UserUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateManyWithoutCustomerNestedInput = {
  create?: InputMaybe<Array<OrderCreateWithoutCustomerInput>>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
};

export type OrderUpdateManyWithoutDeliveryLocationNestedInput = {
  create?: InputMaybe<Array<OrderCreateWithoutDeliveryLocationInput>>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
};

export type OrderUpdateOneRequiredWithoutJobsNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutJobsInput>;
  create?: InputMaybe<OrderCreateWithoutJobsInput>;
  update?: InputMaybe<OrderUpdateWithoutJobsInput>;
  upsert?: InputMaybe<OrderUpsertWithoutJobsInput>;
};

export type OrderUpdateWithoutJobsInput = {
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutOrdersNestedInput>;
  deliveryLocation?: InputMaybe<DeliveryLocationUpdateOneRequiredWithoutOrdersNestedInput>;
  jobName?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobNeededAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  jobReceiveFrom?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobSizeHeight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  jobSizeUnit?: InputMaybe<EnumJobSizeUnitFieldUpdateOperationsInput>;
  jobSizeWidth?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  jobWastable?: InputMaybe<IntFieldUpdateOperationsInput>;
  orderAttachments?: InputMaybe<OrderAttachmentUpdateManyWithoutOrderNestedInput>;
  purchaseOrderNo?: InputMaybe<StringFieldUpdateOperationsInput>;
  transportTags?: InputMaybe<TransportTagUpdateManyWithoutOrderNestedInput>;
  user?: InputMaybe<UserUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpsertWithoutJobsInput = {
  create: OrderCreateWithoutJobsInput;
  update: OrderUpdateWithoutJobsInput;
};

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  cancelReason?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  deliveryLocation?: InputMaybe<DeliveryLocationRelationFilter>;
  deliveryLocationId?: InputMaybe<UuidFilter>;
  documentDate?: InputMaybe<DateTimeFilter>;
  documentNo?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  invoiced?: InputMaybe<BoolFilter>;
  jobName?: InputMaybe<StringFilter>;
  jobNeededAmount?: InputMaybe<IntFilter>;
  jobReceiveFrom?: InputMaybe<StringFilter>;
  jobSizeHeight?: InputMaybe<DecimalFilter>;
  jobSizeUnit?: InputMaybe<EnumJobSizeUnitFilter>;
  jobSizeWidth?: InputMaybe<DecimalFilter>;
  jobWastable?: InputMaybe<IntFilter>;
  jobs?: InputMaybe<JobListRelationFilter>;
  orderAttachments?: InputMaybe<OrderAttachmentListRelationFilter>;
  orderStatus?: InputMaybe<EnumOrderStatusTypeFilter>;
  purchaseOrderNo?: InputMaybe<StringFilter>;
  transportTags?: InputMaybe<TransportTagListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
};

export type OrderWhereUniqueInput = {
  documentNo?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export type Pdf = {
  __typename?: "Pdf";
  /** API relative URI to pdf file */
  uri: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  /**
   * Roles:
   *     MAIN_MASTER,
   *     MAIN_VIEW,
   *     CUSTOMER_VIEW,
   *     CUSTOMER_MASTER,
   *     JOB_VIEW,
   *     JOB_MASTER,
   *     ORDER_MASTER,
   *     ORDER_VIEW,
   *
   */
  customer?: Maybe<Customer>;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     MAIN_VIEW,
   *     CUSTOMER_VIEW,
   *     CUSTOMER_MASTER,
   *     JOB_VIEW,
   *     JOB_MASTER,
   *     ORDER_MASTER,
   *     ORDER_VIEW
   *
   */
  customers: Array<Customer>;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     MAIN_VIEW,
   *     CUSTOMER_VIEW,
   *     CUSTOMER_MASTER,
   *     JOB_VIEW,
   *     JOB_MASTER,
   *     ORDER_MASTER,
   *     ORDER_VIEW,
   *     LOCATION_VIEW,
   *     LOCATION_MASTER
   *
   */
  deliveryLocation?: Maybe<DeliveryLocation>;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     MAIN_VIEW,
   *     CUSTOMER_VIEW,
   *     CUSTOMER_MASTER,
   *     JOB_VIEW,
   *     JOB_MASTER,
   *     ORDER_MASTER,
   *     ORDER_VIEW,
   *     LOCATION_VIEW,
   *     LOCATION_MASTER
   *
   */
  deliveryLocations: Array<DeliveryLocation>;
  /**
   * Roles:
   *   MAIN_MASTER,
   *   MAIN_VIEW,
   *   DELIVERYNOTE_MASTER,
   *   DELIVERYNOTE_VIEW
   *
   */
  deliveryNote: DeliveryNote;
  /**
   * Roles:
   *   MAIN_MASTER,
   *   MAIN_VIEW,
   *   DELIVERYNOTE_MASTER,
   *   DELIVERYNOTE_VIEW
   *
   */
  deliveryNotes: Array<DeliveryNote>;
  /** Any Roles can access */
  histories: Array<History>;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     MAIN_VIEW,
   *     ORDER_MASTER,
   *     ORDER_VIEW,
   *     JOB_VIEW,
   *     JOB_MASTER
   *
   */
  job: Job;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     MAIN_VIEW,
   *     ORDER_MASTER,
   *     ORDER_VIEW,
   *     JOB_VIEW,
   *     JOB_MASTER,
   *
   */
  jobLogs: Array<JobLog>;
  /**
   * Roles:
   *     MAIN_MASTER,
   *     MAIN_VIEW,
   *     ORDER_MASTER,
   *     ORDER_VIEW,
   *     JOB_VIEW,
   *     JOB_MASTER
   *
   */
  jobs: Array<Job>;
  me: User;
  /**
   * Roles:
   *     MAIN_MASTER
   *     MAIN_VIEW
   *     ORDER_MASTER
   *     ORDER_VIEW
   *     INVOICE_MASTER
   *     INVOICE_VIEW
   *
   */
  order?: Maybe<Order>;
  /**
   * Roles:
   *   MAIN_MASTER,
   *   MAIN_VIEW,
   *   INVOICE_MASTER,
   *   INVOICE_VIEW
   */
  orderByJobId: Order;
  /**
   * Roles:
   *   MAIN_MASTER,
   *   MAIN_VIEW,
   *   INVOICE_MASTER,
   *   INVOICE_VIEW
   */
  orderByTransportTagId: Order;
  /**
   * Roles:
   *       MAIN_MASTER
   *       MAIN_VIEW
   *       ORDER_MASTER
   *       ORDER_VIEW
   *       INVOICE_MASTER
   *       INVOICE_VIEW
   *
   */
  orders: Array<Order>;
  /**
   * Roles:
   *       MAIN_MASTER
   *       MAIN_VIEW
   *       TRANSPORTTAG_VIEW
   *       TRANSPORTTAG_MASTER
   *       ORDER_MASTER
   *       ORDER_VIEW
   *       TRANSPORTTAGLOG_MASTER
   *       TRANSPORTTAGLOG_VIEW
   *
   */
  transportTag?: Maybe<TransportTag>;
  /**
   * Roles:
   *       MAIN_MASTER
   *       MAIN_VIEW
   *       TRANSPORTTAGLOG_VIEW
   *       TRANSPORTTAGLOG_MASTER
   *       TRANSPORTTAG_MASTER
   *       TRANSPORTTAG_VIEW
   *
   */
  transportTagLogs: Array<TransportTagLog>;
  /**
   * Roles:
   *       MAIN_MASTER
   *       MAIN_VIEW
   *       TRANSPORTTAG_VIEW
   *       TRANSPORTTAG_MASTER
   *       ORDER_MASTER
   *       ORDER_VIEW
   *
   */
  transportTags: Array<TransportTag>;
  /**
   * Roles:
   *       MAIN_MASTER
   *       MAIN_VIEW
   *       USER_MASTER
   *       USER_VIEW
   *       ORDER_MASTER
   *       ORDER_VIEW
   *       JOB_MASTER
   *       JOB_VIEW
   *       TRANSPORTTAG_MASTER
   *       TRANSPORTTAG_VIEW
   *       TRANSPORTTAGLOG_MASTER
   *       TRANSPORTTAGLOG_VIEW
   *
   *
   */
  user: User;
  /**
   * Roles:
   *       MAIN_MASTER
   *       MAIN_VIEW
   *       USER_VIEW
   *       USER_MASTER
   *
   */
  users: Array<User>;
};

export type QueryCustomerArgs = {
  where: CustomerWhereUniqueInput;
};

export type QueryCustomersArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CustomerWhereInput>;
};

export type QueryDeliveryLocationArgs = {
  cursor?: InputMaybe<DeliveryLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeliveryLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeliveryLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<DeliveryLocationWhereInput>;
};

export type QueryDeliveryLocationsArgs = {
  cursor?: InputMaybe<DeliveryLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeliveryLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeliveryLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<DeliveryLocationWhereInput>;
};

export type QueryDeliveryNoteArgs = {
  where: DeliveryNoteWhereUniqueInput;
};

export type QueryDeliveryNotesArgs = {
  cursor?: InputMaybe<DeliveryNoteWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeliveryNoteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeliveryNoteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<DeliveryNoteWhereInput>;
};

export type QueryHistoriesArgs = {
  cursor?: InputMaybe<HistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<HistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<HistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HistoryWhereInput>;
};

export type QueryJobArgs = {
  where: JobWhereUniqueInput;
};

export type QueryJobLogsArgs = {
  cursor?: InputMaybe<JobLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<JobLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<JobLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<JobLogWhereInput>;
};

export type QueryJobsArgs = {
  cursor?: InputMaybe<JobWhereUniqueInput>;
  distinct?: InputMaybe<Array<JobScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<JobOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<JobWhereInput>;
};

export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};

export type QueryOrderByJobIdArgs = {
  where: WhereIdInput;
};

export type QueryOrderByTransportTagIdArgs = {
  where: WhereIdInput;
};

export type QueryOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<OrderWhereInput>;
};

export type QueryTransportTagArgs = {
  where: TransportTagWhereUniqueInput;
};

export type QueryTransportTagLogsArgs = {
  cursor?: InputMaybe<TransportTagLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransportTagLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransportTagLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TransportTagLogWhereInput>;
};

export type QueryTransportTagsArgs = {
  cursor?: InputMaybe<TransportTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransportTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransportTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TransportTagWhereInput>;
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = "default",
  Insensitive = "insensitive",
}

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export enum SpottingBlockType {
  ExistingFilm = "EXISTING_FILM",
  NewFilm = "NEW_FILM",
}

export enum SpottingType {
  Embose = "EMBOSE",
  Normal = "NORMAL",
  Texture = "TEXTURE",
  XEmbose = "X_EMBOSE",
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type TransportTag = {
  __typename?: "TransportTag";
  _count: TransportTagCount;
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["String"];
  deliveredCount: Scalars["Int"];
  deliveryLocation: DeliveryLocation;
  deliveryLocationId: Scalars["String"];
  description: Scalars["String"];
  documentNo: Scalars["String"];
  finalizeOrder: Scalars["Boolean"];
  id: Scalars["ID"];
  inTransitCount: Scalars["Int"];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars["String"]>;
  productAmount: Scalars["Int"];
  ref1: Scalars["String"];
  ref2: Scalars["String"];
  status: TransportTagStatusType;
  transportTagLogs?: Maybe<Array<TransportTagLog>>;
  updatedAt: Scalars["DateTime"];
};

export type TransportTagCount = {
  __typename?: "TransportTagCount";
  transportTagLogs: Scalars["Int"];
};

export type TransportTagCreateInput = {
  customer: CustomerCreateNestedOneWithoutTransportTagInput;
  deliveredCount?: InputMaybe<Scalars["Int"]>;
  deliveryLocation: DeliveryLocationCreateNestedOneWithoutTransportTagInput;
  description?: InputMaybe<Scalars["String"]>;
  finalizeOrder?: InputMaybe<Scalars["Boolean"]>;
  inTransitCount?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTransportTagsInput>;
  productAmount?: InputMaybe<Scalars["Int"]>;
  ref1?: InputMaybe<Scalars["String"]>;
  ref2?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<TransportTagStatusType>;
  transportTagLogs?: InputMaybe<TransportTagLogCreateNestedManyWithoutTransportTagInput>;
};

export type TransportTagCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<TransportTagWhereUniqueInput>>;
};

export type TransportTagCreateNestedManyWithoutDeliveryLocationInput = {
  connect?: InputMaybe<Array<TransportTagWhereUniqueInput>>;
};

export type TransportTagCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<TransportTagWhereUniqueInput>>;
};

export type TransportTagCreateNestedOneWithoutTransportTagLogsInput = {
  connect?: InputMaybe<TransportTagWhereUniqueInput>;
};

export type TransportTagCreateWithoutCustomerInput = {
  deliveredCount?: InputMaybe<Scalars["Int"]>;
  deliveryLocation: DeliveryLocationCreateNestedOneWithoutTransportTagInput;
  description?: InputMaybe<Scalars["String"]>;
  finalizeOrder?: InputMaybe<Scalars["Boolean"]>;
  inTransitCount?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTransportTagsInput>;
  productAmount?: InputMaybe<Scalars["Int"]>;
  ref1?: InputMaybe<Scalars["String"]>;
  ref2?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<TransportTagStatusType>;
  transportTagLogs?: InputMaybe<TransportTagLogCreateNestedManyWithoutTransportTagInput>;
};

export type TransportTagCreateWithoutDeliveryLocationInput = {
  customer: CustomerCreateNestedOneWithoutTransportTagInput;
  deliveredCount?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  finalizeOrder?: InputMaybe<Scalars["Boolean"]>;
  inTransitCount?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTransportTagsInput>;
  productAmount?: InputMaybe<Scalars["Int"]>;
  ref1?: InputMaybe<Scalars["String"]>;
  ref2?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<TransportTagStatusType>;
  transportTagLogs?: InputMaybe<TransportTagLogCreateNestedManyWithoutTransportTagInput>;
};

export type TransportTagCreateWithoutOrderInput = {
  customer: CustomerCreateNestedOneWithoutTransportTagInput;
  deliveredCount?: InputMaybe<Scalars["Int"]>;
  deliveryLocation: DeliveryLocationCreateNestedOneWithoutTransportTagInput;
  description?: InputMaybe<Scalars["String"]>;
  finalizeOrder?: InputMaybe<Scalars["Boolean"]>;
  inTransitCount?: InputMaybe<Scalars["Int"]>;
  productAmount?: InputMaybe<Scalars["Int"]>;
  ref1?: InputMaybe<Scalars["String"]>;
  ref2?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<TransportTagStatusType>;
  transportTagLogs?: InputMaybe<TransportTagLogCreateNestedManyWithoutTransportTagInput>;
};

export type TransportTagListRelationFilter = {
  every?: InputMaybe<TransportTagWhereInput>;
  none?: InputMaybe<TransportTagWhereInput>;
  some?: InputMaybe<TransportTagWhereInput>;
};

export type TransportTagLog = {
  __typename?: "TransportTagLog";
  _count: TransportTagLogCount;
  createdAt: Scalars["DateTime"];
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lat?: Maybe<Scalars["Decimal"]>;
  lon?: Maybe<Scalars["Decimal"]>;
  status: TransportTagStatusType;
  /** xHideField({ match: "*@(Create|Update)*Input" }) */
  transportTag: TransportTag;
  transportTagId: Scalars["String"];
  transportTagLogAttachments: Array<TransportTagLogAttachment>;
  user: User;
  userId: Scalars["String"];
};

export type TransportTagLogAttachment = {
  __typename?: "TransportTagLogAttachment";
  createdAt: Scalars["DateTime"];
  fileName: Scalars["String"];
  id: Scalars["ID"];
  transportTagLog: TransportTagLog;
  transportTagLogId: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type TransportTagLogAttachmentCreateNestedManyWithoutTransportTagLogInput =
  {
    connect?: InputMaybe<Array<TransportTagLogAttachmentWhereUniqueInput>>;
  };

export type TransportTagLogAttachmentListRelationFilter = {
  every?: InputMaybe<TransportTagLogAttachmentWhereInput>;
  none?: InputMaybe<TransportTagLogAttachmentWhereInput>;
  some?: InputMaybe<TransportTagLogAttachmentWhereInput>;
};

export type TransportTagLogAttachmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransportTagLogAttachmentWhereInput = {
  AND?: InputMaybe<Array<TransportTagLogAttachmentWhereInput>>;
  NOT?: InputMaybe<Array<TransportTagLogAttachmentWhereInput>>;
  OR?: InputMaybe<Array<TransportTagLogAttachmentWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fileName?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  transportTagLog?: InputMaybe<TransportTagLogRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TransportTagLogAttachmentWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type TransportTagLogCount = {
  __typename?: "TransportTagLogCount";
  transportTagLogAttachments: Scalars["Int"];
};

export type TransportTagLogCreateInput = {
  errorMessage?: InputMaybe<Scalars["String"]>;
  lat?: InputMaybe<Scalars["Decimal"]>;
  lon?: InputMaybe<Scalars["Decimal"]>;
  status?: InputMaybe<TransportTagStatusType>;
  transportTag: TransportTagCreateNestedOneWithoutTransportTagLogsInput;
  transportTagLogAttachments?: InputMaybe<TransportTagLogAttachmentCreateNestedManyWithoutTransportTagLogInput>;
};

export type TransportTagLogCreateNestedManyWithoutTransportTagInput = {
  connect?: InputMaybe<Array<TransportTagLogWhereUniqueInput>>;
};

export type TransportTagLogListRelationFilter = {
  every?: InputMaybe<TransportTagLogWhereInput>;
  none?: InputMaybe<TransportTagLogWhereInput>;
  some?: InputMaybe<TransportTagLogWhereInput>;
};

export type TransportTagLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransportTagLogOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  errorMessage?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lat?: InputMaybe<SortOrder>;
  lon?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  transportTag?: InputMaybe<TransportTagOrderByWithRelationInput>;
  transportTagId?: InputMaybe<SortOrder>;
  transportTagLogAttachments?: InputMaybe<TransportTagLogAttachmentOrderByRelationAggregateInput>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
};

export type TransportTagLogRelationFilter = {
  is?: InputMaybe<TransportTagLogWhereInput>;
  isNot?: InputMaybe<TransportTagLogWhereInput>;
};

export enum TransportTagLogScalarFieldEnum {
  CreatedAt = "createdAt",
  ErrorMessage = "errorMessage",
  Id = "id",
  Lat = "lat",
  Lon = "lon",
  Status = "status",
  TransportTagId = "transportTagId",
  UserId = "userId",
}

export type TransportTagLogWhereInput = {
  AND?: InputMaybe<Array<TransportTagLogWhereInput>>;
  NOT?: InputMaybe<Array<TransportTagLogWhereInput>>;
  OR?: InputMaybe<Array<TransportTagLogWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  errorMessage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  lat?: InputMaybe<DecimalNullableFilter>;
  lon?: InputMaybe<DecimalNullableFilter>;
  status?: InputMaybe<EnumTransportTagStatusTypeFilter>;
  transportTag?: InputMaybe<TransportTagRelationFilter>;
  transportTagId?: InputMaybe<UuidFilter>;
  transportTagLogAttachments?: InputMaybe<TransportTagLogAttachmentListRelationFilter>;
  user?: InputMaybe<UserRelationFilter>;
};

export type TransportTagLogWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type TransportTagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransportTagOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  deliveredCount?: InputMaybe<SortOrder>;
  deliveryLocation?: InputMaybe<DeliveryLocationOrderByWithRelationInput>;
  description?: InputMaybe<SortOrder>;
  documentNo?: InputMaybe<SortOrder>;
  finalizeOrder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inTransitCount?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  productAmount?: InputMaybe<SortOrder>;
  ref1?: InputMaybe<SortOrder>;
  ref2?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  transportTagLogs?: InputMaybe<TransportTagLogOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TransportTagRelationFilter = {
  is?: InputMaybe<TransportTagWhereInput>;
  isNot?: InputMaybe<TransportTagWhereInput>;
};

export enum TransportTagScalarFieldEnum {
  CreatedAt = "createdAt",
  CustomerId = "customerId",
  DeliveredCount = "deliveredCount",
  DeliveryLocationId = "deliveryLocationId",
  Description = "description",
  DocumentNo = "documentNo",
  FinalizeOrder = "finalizeOrder",
  Id = "id",
  InTransitCount = "inTransitCount",
  OrderId = "orderId",
  ProductAmount = "productAmount",
  Ref1 = "ref1",
  Ref2 = "ref2",
  Status = "status",
  UpdatedAt = "updatedAt",
}

export enum TransportTagStatusType {
  Delivered = "DELIVERED",
  InTransit = "IN_TRANSIT",
  Waiting = "WAITING",
}

export type TransportTagUpdateManyWithoutCustomerNestedInput = {
  create?: InputMaybe<Array<TransportTagCreateWithoutCustomerInput>>;
  delete?: InputMaybe<Array<TransportTagWhereUniqueInput>>;
};

export type TransportTagUpdateManyWithoutDeliveryLocationNestedInput = {
  create?: InputMaybe<Array<TransportTagCreateWithoutDeliveryLocationInput>>;
  delete?: InputMaybe<Array<TransportTagWhereUniqueInput>>;
};

export type TransportTagUpdateManyWithoutOrderNestedInput = {
  create?: InputMaybe<Array<TransportTagCreateWithoutOrderInput>>;
  delete?: InputMaybe<Array<TransportTagWhereUniqueInput>>;
};

export type TransportTagWhereInput = {
  AND?: InputMaybe<Array<TransportTagWhereInput>>;
  NOT?: InputMaybe<Array<TransportTagWhereInput>>;
  OR?: InputMaybe<Array<TransportTagWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  deliveredCount?: InputMaybe<IntFilter>;
  deliveryLocation?: InputMaybe<DeliveryLocationRelationFilter>;
  description?: InputMaybe<StringFilter>;
  documentNo?: InputMaybe<StringFilter>;
  finalizeOrder?: InputMaybe<BoolFilter>;
  id?: InputMaybe<UuidFilter>;
  inTransitCount?: InputMaybe<IntFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<UuidNullableFilter>;
  productAmount?: InputMaybe<IntFilter>;
  ref1?: InputMaybe<StringFilter>;
  ref2?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumTransportTagStatusTypeFilter>;
  transportTagLogs?: InputMaybe<TransportTagLogListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TransportTagWhereUniqueInput = {
  documentNo?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export type UpdateGeoInput = {
  lat: DecimalFieldUpdateOperationsInput;
  lon: DecimalFieldUpdateOperationsInput;
};

export type User = {
  __typename?: "User";
  _count: UserCount;
  code: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lineUser?: Maybe<LineUser>;
  name: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  userRoles?: Maybe<Array<UserRole>>;
};

export type UserCount = {
  __typename?: "UserCount";
  Order: Scalars["Int"];
  histories: Scalars["Int"];
  jobLog: Scalars["Int"];
  transportTagLog: Scalars["Int"];
  userRoles: Scalars["Int"];
};

export type UserCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
};

export type UserCreateOrConnectWithoutLineUserInput = {
  create: UserCreateWithoutLineUserInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOrderInput = {
  create: UserCreateWithoutOrderInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutLineUserInput = {
  code: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  userRoles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutOrderInput = {
  code: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  userRoles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
};

export type UserOrderByWithRelationInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  lineUser?: InputMaybe<LineUserOrderByWithRelationInput>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userRoles?: InputMaybe<UserRoleOrderByRelationAggregateInput>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserRole = {
  __typename?: "UserRole";
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  roleCode: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  /** xHideField({input: true}) */
  userId: Scalars["String"];
};

export type UserRoleCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
};

export type UserRoleCreateWithoutUserInput = {
  roleCode: Scalars["String"];
};

export type UserRoleListRelationFilter = {
  every?: InputMaybe<UserRoleWhereInput>;
  none?: InputMaybe<UserRoleWhereInput>;
  some?: InputMaybe<UserRoleWhereInput>;
};

export type UserRoleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserRoleUpdateManyWithoutUserNestedInput = {
  create?: InputMaybe<Array<UserRoleCreateWithoutUserInput>>;
  delete?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
};

export type UserRoleUserIdRoleCodeCompoundUniqueInput = {
  roleCode: Scalars["String"];
  userId: Scalars["String"];
};

export type UserRoleWhereInput = {
  AND?: InputMaybe<Array<UserRoleWhereInput>>;
  NOT?: InputMaybe<Array<UserRoleWhereInput>>;
  OR?: InputMaybe<Array<UserRoleWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  roleCode?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UserRoleWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  userId_roleCode?: InputMaybe<UserRoleUserIdRoleCodeCompoundUniqueInput>;
};

export enum UserScalarFieldEnum {
  Code = "code",
  CreatedAt = "createdAt",
  Id = "id",
  IsDeleted = "isDeleted",
  Name = "name",
  RefreshTokenHash = "refreshTokenHash",
  UpdatedAt = "updatedAt",
}

export type UserUpdateInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  userRoles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateOneWithoutLineUserNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLineUserInput>;
  create?: InputMaybe<UserCreateWithoutLineUserInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<UserUpdateWithoutLineUserInput>;
  upsert?: InputMaybe<UserUpsertWithoutLineUserInput>;
};

export type UserUpdateOneWithoutOrderNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<UserCreateWithoutOrderInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<UserUpdateWithoutOrderInput>;
  upsert?: InputMaybe<UserUpsertWithoutOrderInput>;
};

export type UserUpdateWithoutLineUserInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  userRoles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutOrderInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  userRoles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
};

export type UserUpsertWithoutLineUserInput = {
  create: UserCreateWithoutLineUserInput;
  update: UserUpdateWithoutLineUserInput;
};

export type UserUpsertWithoutOrderInput = {
  create: UserCreateWithoutOrderInput;
  update: UserUpdateWithoutOrderInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  lineUser?: InputMaybe<LineUserRelationFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userRoles?: InputMaybe<UserRoleListRelationFilter>;
};

export type UserWhereUniqueInput = {
  code?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export type UuidFilter = {
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type UuidNullableFilter = {
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type WhereIdInput = {
  id: Scalars["String"];
};
