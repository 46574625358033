// token
import { BehaviorSubject, Observable } from "rxjs";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { getApiRoot } from "./util-helper";
import { liffCloseWindow, liffIsInClient } from "./util-line-liff";

const ls = window.localStorage;

const TOKEN_STORAGE_NAME = "orangep:token";
const REFRESH_TOKEN_STORAGE_NAME = "orangep:refreshToken";

const tokenSubject = new BehaviorSubject<string>("");

const refreshTokenSubject = new BehaviorSubject<string>(
  ls.getItem(REFRESH_TOKEN_STORAGE_NAME) || ""
);

const liffTokenSubject = new BehaviorSubject<string>("");

refreshTokenSubject.subscribe({
  next: (token) => {
    ls.setItem(REFRESH_TOKEN_STORAGE_NAME, token);
  },
});

tokenSubject.subscribe({
  next: (token) => {
    ls.setItem(TOKEN_STORAGE_NAME, token);
  },
});

tokenSubject.subscribe({
  next: (token) => {
    ls.setItem(TOKEN_STORAGE_NAME, token);
    if (token) {
      const decoded = jwt_decode(token) as any;
      const exp = decoded.exp;
      const now = new Date().getTime() / 1000;
      const diff = exp - now - 60;
      // console.log("diff", exp, now, diff);
      if (diff > 0) {
        // console.log("setTimeout", diff * 1000);
        setTimeout(() => {
          // console.log("timeout refreshtoken");
          refreshCurrentToken();
        }, diff * 1000);
      } else {
        refreshCurrentToken();
      }
    }
  },
});

export function getToken(): string {
  return tokenSubject.getValue();
}

export function getRefreshToken(): string {
  return refreshTokenSubject.getValue();
}

export function setToken(token: string) {
  tokenSubject.next(token);
}

export function setRefreshToken(token: string) {
  refreshTokenSubject.next(token);
}

export function getTokenObservable(): Observable<string> {
  return tokenSubject.asObservable();
}

export function getRefreshTokenObservable(): Observable<string> {
  return refreshTokenSubject.asObservable();
}

export function refreshCurrentToken() {
  axios
    .post(
      `${getApiRoot()}/graphql`,
      {
        query: jsonToGraphQLQuery({
          mutation: {
            refreshToken: {
              accessToken: true,
              refreshToken: true,
            },
          },
        }),
      },
      {
        headers: {
          Authorization: `Bearer ${getRefreshToken()}`,
        },
      }
    )
    .then((response) => {
      const { data, errors } = response.data;
      if (data) {
        const { refreshToken, accessToken } = data.refreshToken;
        refreshTokenSubject.next(refreshToken);
        tokenSubject.next(accessToken);
      }
      if (errors) {
        refreshTokenSubject.next("");
        tokenSubject.next("");
        // if (liffIsInClient()) {
        //   liffCloseWindow();
        //   // } else {
        //   // window.alert("Session timeout");
        //   // window.location.pathname = "/";
        // }
      }
    })
    .catch((e) => {
      setRefreshToken("");
      setToken("");
    });
}

export function setLiffToken(liffToken: string) {
  liffTokenSubject.next(liffToken);
}

export function getLiffTokenObservable() {
  return liffTokenSubject.asObservable();
}
