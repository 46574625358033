// reactive
import { BehaviorSubject, Observable, share, distinctUntilChanged } from "rxjs";

const Subjects$: { string?: BehaviorSubject<any> } = {};

const Observables$: {
  string?: Observable<any>;
} = {};

const StorageSubject$: { string?: BehaviorSubject<any> } = {};
const StorageObservables$: { string?: Observable<any> } = {};

const storage = window.sessionStorage;

function prepareObservable<T>(subjectName: string, defaultValue: T) {
  if (!Subjects$[subjectName]) {
    window.console.log("Create a new Subject: " + subjectName, defaultValue);
    const obj = new BehaviorSubject<T>(defaultValue);
    Subjects$[subjectName] = obj;
  }

  if (!Observables$[subjectName]) {
    const observable = Subjects$[subjectName].pipe(
      share(),
      distinctUntilChanged()
    );
    Observables$[subjectName] = observable;
  }
}

function prepareStorageObservable<T>(subjectName: string, defaultValue: T) {
  if (!StorageSubject$[subjectName]) {
    const obj = new BehaviorSubject<T>(
      storage.getItem(subjectName)
        ? JSON.parse(storage.getItem(subjectName))
        : defaultValue
    );
    obj.subscribe({
      next: (value: T) => {
        storage.setItem(subjectName, JSON.stringify(value));
      },
    });
    StorageSubject$[subjectName] = obj;
  }

  if (!StorageObservables$[subjectName]) {
    const observable = StorageSubject$[subjectName].pipe(
      share(),
      distinctUntilChanged()
    );
    StorageObservables$[subjectName] = observable;
  }
}

export function getBehaviorSubject<T>(
  subjectName: string,
  defaultValue: T
): BehaviorSubject<T> {
  prepareObservable<T>(subjectName, defaultValue);
  return Subjects$[subjectName];
}

export function getStorageBehaviorSubject<T>(
  subjectName: string,
  defaultValue: T
): BehaviorSubject<T> {
  prepareStorageObservable(subjectName, defaultValue);
  return StorageSubject$[subjectName];
}

export function getBehaviorObservable<T>(
  subjectName: string,
  defaultValue: T
): Observable<T> {
  prepareObservable<T>(subjectName, defaultValue);
  return Observables$[subjectName];
}

export function getStorageBehaviorObservable<T>(
  subjectName: string,
  defaultValue: T
): Observable<T> {
  prepareStorageObservable(subjectName, defaultValue);
  return StorageObservables$[subjectName];
}
