// Roles
// take care of it

// RoleType copied from api - src/helpers/roles/index.ts
export enum RoleType {
  MAIN_VIEW = "MAIN_VIEW",
  MAIN_MASTER = "MAIN_MASTER",
  USER_VIEW = "USER_VIEW",
  USER_MASTER = "USER_MASTER",
  CUSTOMER_VIEW = "CUSTOMER_VIEW",
  CUSTOMER_MASTER = "CUSTOMER_MASTER",
  LOCATION_VIEW = "LOCATION_VIEW",
  LOCATION_MASTER = "LOCATION_MASTER",
  ORDER_VIEW = "ORDER_VIEW",
  ORDER_MASTER = "ORDER_MASTER",
  JOB_VIEW = "JOB_VIEW",
  JOB_MASTER = "JOB_MASTER",
  TRANSPORTTAG_MASTER = "TRANSPORTTAG_MASTER",
  TRANSPORTTAG_VIEW = "TRANSPORTTAG_VIEW",
  TRANSPORTTAGLOG_MASTER = "TRANSPORTTAGLOG_MASTER",
  TRANSPORTTAGLOG_VIEW = "TRANSPORTTAGLOG_VIEW",
  INVOICE_MASTER = "INVOICE_MASTER",
  INVOICE_VIEW = "INVOICE_VIEW",
  DELIVERYNOTE_MASTER = "DELIVERYNOTE_MASTER",
  DELIVERYNOTE_VIEW = "DELIVERYNOTE_VIEW",
}

enum RoleEnum {
  MAIN = "MAIN",
  CUSTOMER = "CUSTOMER",
  USER = "USER",
  ORDER = "ORDER",
  JOB = "JOB",
  TRANSPORTTAG = "TRANSPORTTAG",
  TRANSPORTTAGLOG = "TRANSPORTTAGLOG",
  INVOICE = "INVOICE",
  DELIVERYNOTE = "DELIVERYNOTE",
}

enum SubRoleEnum {
  MASTER = "MASTER",
  VIEW = "VIEW",
}

// const ROLE_MAIN = "MAIN";
// const ROLE_CUSTOMER = "CUSTOMER";
// const ROLE_USER = "USER";
// const ROLE_ORDER = "ORDER";

// const SUBROLE_MASTER = "MASTER";
// const SUBROLE_VIEW = "VIEW";

const RoleCode: { [key in keyof typeof RoleEnum]: RoleEnum } = {
  MAIN: RoleEnum.MAIN,
  CUSTOMER: RoleEnum.CUSTOMER,
  USER: RoleEnum.USER,
  ORDER: RoleEnum.ORDER,
  JOB: RoleEnum.JOB,
  TRANSPORTTAG: RoleEnum.TRANSPORTTAG,
  TRANSPORTTAGLOG: RoleEnum.TRANSPORTTAGLOG,
  INVOICE: RoleEnum.INVOICE,
  DELIVERYNOTE: RoleEnum.DELIVERYNOTE,
};

export const SubRoleCode: { [key in keyof typeof SubRoleEnum]: SubRoleEnum } = {
  MASTER: SubRoleEnum.MASTER,
  VIEW: SubRoleEnum.VIEW,
};

export const roleTranslation: { [key in keyof typeof RoleEnum]: string } = {
  MAIN: "สิทธิ์จัดการระบบ",
  USER: "ข้อมูลผู้ใช้งาน",
  CUSTOMER: "ข้อมูลลูกค้า",
  ORDER: "ใบออเดอร์",
  JOB: "การทำงาน",
  TRANSPORTTAG: "Tag ส่งของ",
  TRANSPORTTAGLOG: "การส่งของ",
  DELIVERYNOTE: "ใบส่งของชั่วคราว",
  INVOICE: "บัญชีออกบิล",
};

export const subRoleTranslation: { [key in keyof typeof SubRoleEnum]: string } =
  {
    MASTER: "การจัดการ",
    VIEW: "ดูข้อมูล",
  };

export function getRoleCode() {
  return { ...RoleCode };
}

export function getSubRoleCode() {
  return { ...SubRoleCode };
}

export function getRoleTranslations() {
  return { ...roleTranslation };
}
