// export * from "./lib";

import { onUnLoad, onLoad } from "./util-window-load";

export * as Apis from "./generated-graphql-types";
export * from "./util-role";
export * from "./util-helper";
export * from "./util-date";
export * from "./util-pdf";
export * from "./util-token";
export * from "./util-user";
export * from "./util-reactive";
export * from "./util-notify";
export * from "./util-axios";
export * from "./util-number";
export * from "./util-line-liff";
export * from "./util-job";
export * from "./util-reactive-ui";
export * as Translation from "./util-translation";
export { logout } from "./util-window-load";

window.addEventListener("load", onLoad);
window.addEventListener("unload", onUnLoad);
