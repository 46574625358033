import liff from "@line/liff/core";
import LiffLogin from "@line/liff/login";
import LiffLoggedOut from "@line/liff/logout";
import LiffIsInClient from "@line/liff/is-in-client";
import LiffCloseWindow from "@line/liff/close-window";
import LiffGetContext from "@line/liff/get-context";
import LiffGetOs from "@line/liff/get-os";
import LiffScancodeV2 from "@line/liff/scan-code-v2";
import LiffSendMessages from "@line/liff/send-messages";
import LiffOpenWindow from "@line/liff/open-window";
import LiffIsLoggedIn from "@line/liff/is-logged-in";
import LiffGetProfile from "@line/liff/get-profile";
import LiffGetAccessToken from "@line/liff/get-access-token";
import LIFFInspectorPlugin from "@line/liff-inspector";

liff.use(new LIFFInspectorPlugin());
liff.use(new LiffLogin());
liff.use(new LiffLoggedOut());
liff.use(new LiffIsInClient());
liff.use(new LiffCloseWindow());
liff.use(new LiffGetContext());
liff.use(new LiffGetOs());
liff.use(new LiffScancodeV2());
liff.use(new LiffSendMessages());
liff.use(new LiffOpenWindow());
liff.use(new LiffIsLoggedIn());
liff.use(new LiffGetProfile());
liff.use(new LiffGetAccessToken());

import { Context } from "@liff/store";
// import { createNotify } from "./util-notify";
// import { getUser } from "./util-user";
import { SendMessagesParams } from "@liff/send-messages";
export type ScanCodeResult = {
  value: string | null;
};

export function liffIsInClient(): boolean {
  const isInClient = liff.isInClient();
  window.console.log("liff.isInClient", isInClient);
  return isInClient;
}

export function liffCloseWindow(): void {
  if (liffIsInClient()) {
    window.console.log("liff.closeWindow");
    liff.closeWindow();
  }
}

export function liffGetContext(): Context {
  const context = liff.getContext();
  window.console.log("liff.getContext", context);
  return context;
}

export function liffGetOs() {
  const os = liff.getOS();
  window.console.log("liff.getOS", os);
  return os;
}

export function liffGetVersion() {
  const version = liff.getVersion();
  window.console.log("liff.getVersion", version);
  return version;
}

export function liffScanQR(): Promise<ScanCodeResult> {
  window.console.log("liff.scanCodeV2");
  return liff.scanCodeV2();
}

export async function liffSendMesages(messages: SendMessagesParams) {
  window.console.log("liff.sendMessages", messages);
  try {
    await liff.sendMessages(messages);
  } catch (e) {
    await liff.sendMessages([
      {
        type: "text",
        text: "@orangep/util sendMessages debug",
      },
      {
        type: "text",
        text: JSON.stringify(messages, null, 2),
      },
    ]);
    throw e;
  }
}

export function liffOpenWindow(url: string, external = false) {
  window.console.log(
    `liff.openWindow -- url: ${url} -- external: ${JSON.stringify(external)}`
  );
  liff.openWindow({
    url,
    external,
  });
}

export function liffIsLoggedIn() {
  const isLoggedIn = liff.isLoggedIn();
  window.console.log("liff.isLoggedIn", isLoggedIn);
  return isLoggedIn;
}

export function liffLogin(redirectUri = undefined) {
  window.console.log("liff.login", redirectUri);
  return liff.login({
    redirectUri,
  });
}

export function liffLogout() {
  window.console.log("liff.logout");
  return liff.logout();
}

export function liffGetProfile() {
  const profile = liff.getProfile();
  window.console.log("liff.getProfile", profile);
  return profile;
}

export function liffGetAccessToken() {
  window.console.log("liff.getAccessToken xxxxxxxxx");
  return liff.getAccessToken();
}

export function liffInit() {
  window.console.log("liffInit", LIFF_APP_ID);
  return liff.init({
    liffId: LIFF_APP_ID,
    withLoginOnExternalBrowser: false,
  });
}
